import React, { useContext, useMemo, useRef } from 'react';
import { HorizontalSectionProps } from '@/types';
import HorizontalSection from '@/components/modules/HorizontalSection';
import ParagraphsGroup from '@/components/modules/ParagraphsGroup';
import ImageWrapper from '@/components/modules/ImageWrapper';
import Arrow01 from '@/assets/images/arrows/manifest-arrow-01.svg';
import AnimatedArrow from '@/components/animations/AnimatedArrow';
import { Context as HorizontalScrollingContext } from '@/components/modules/HorizontalScrolling';
import ParallaxFloatElement from '@/components/modules/ParallaxFloatElement';
import Parallax from '@/components/animations/Parallax';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import Fade from '@/components/animations/Fade';

export type Props = { content: string } & Omit<
  HorizontalSectionProps,
  'containerAnimation' | 'trigger'
>;

type StaticData = {
  pesce: FileNode;
  efesto: FileNode;
  uomoAlato: FileNode;
  uomoAnfibio: FileNode;
};

const query = graphql`
  query {
    pesce: file(name: { eq: "manifesto-pesce" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    efesto: file(name: { eq: "manifesto-efesto-elena-salmistraro-desktop" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    uomoAlato: file(name: { eq: "manifesto-uomo-alato-desktop" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    uomoAnfibio: file(name: { eq: "manifesto-uomo-anfibio-desktop" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

function Manifest({
  name,
  background,
  separator,
  mobileRatio,
  desktopRatio,
  content,
}: Props) {
  const data = useStaticQuery<StaticData>(query);
  const section = useRef<HTMLDivElement>(null);
  const containerAnimation = useContext<React.MutableRefObject<GSAPAnimation>>(
    HorizontalScrollingContext
  );
  const elements = useMemo(
    () => [
      {
        item: {
          id: 1,
          name: `pesce-volante`,
          image: { data: getImage(data.pesce) },
        },
      },
      {
        item: {
          id: 2,
          name: `uomo-alato`,
          image: { data: getImage(data.uomoAlato) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
      {
        item: {
          id: 3,
          name: `efesto`,
          image: { data: getImage(data.efesto) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
      {
        item: {
          id: 4,
          name: `uomo-anfibio`,
          image: { data: getImage(data.uomoAnfibio) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
    ],
    []
  );

  return (
    <HorizontalSection
      ref={section}
      name={name}
      containerAnimation={containerAnimation}
      trigger={section}
      background={background}
      separator={separator}
      mobileRatio={mobileRatio}
      desktopRatio={desktopRatio}
    >
      <div className="manifest relative w-full h-full flex flex-col">
        <div className="manifest__images flex-1">
          <Fade delay={1}>
            <Parallax
              className="manifest__fish absolute top-[3vh] left-[5vh] w-[19.9vh]"
              containerAnimation={containerAnimation}
              trigger={section}
              horizontal
              movement={225}
            >
              <ImageWrapper
                data={elements[0].item.image.data}
                alt={elements[0].item.name}
              />
            </Parallax>
          </Fade>
          <Fade delay={1}>
            <ParallaxFloatElement
              className="manifest__top-left-element absolute left-[30vh] top-[16vh] w-[26.66vh]"
              containerAnimation={containerAnimation}
              trigger={section}
              horizontal
              movement={65}
              data={elements[1].item.image.data}
              alt={elements[1].item.name}
            />
          </Fade>
          <div className="manifest__top-right-element absolute right-[25vh] top-[16vh] w-[60vh]">
            <div className="relative">
              <AnimatedArrow
                className="manifest__arrow-01 absolute bottom-0 -left-[8vh] w-[30vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                horizontal
                delay={1}
              >
                <Arrow01 className="fill-current" />
              </AnimatedArrow>
              <Fade delay={1}>
                <Parallax
                  containerAnimation={containerAnimation}
                  trigger={section}
                  movement={30}
                  horizontal
                >
                  <ImageWrapper
                    data={elements[2].item.image.data}
                    alt={elements[2].item.name}
                  />
                </Parallax>
              </Fade>
            </div>
          </div>
        </div>
        <div className="manifest__content flex-1 w-full">
          <Fade delay={1}>
            <div className="mt-[14vh] flex justify-center items-center max-w-[88.88vh] m-auto text-[2.11vh]">
              <ParagraphsGroup content={content} />
            </div>
          </Fade>
        </div>
        <Fade delay={1}>
          <ParallaxFloatElement
            className="manifest-man absolute bottom-[5vh] z-20 -right-[20vh] w-[20.43vh]"
            containerAnimation={containerAnimation}
            trigger={section}
            horizontal
            movement={-30}
            maxRadius={elements[3].item.maxRadius}
            maxSpeed={elements[3].item.maxSpeed}
            data={elements[3].item.image.data}
            alt={elements[3].item.name}
          />
        </Fade>
      </div>
    </HorizontalSection>
  );
}

export default Manifest;
