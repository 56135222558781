import React, { useEffect, useRef, useContext } from 'react';
import { gsap } from 'gsap';
import { Power1 } from 'gsap/gsap-core';
import { BaseComponentProps } from '@/types';
import { app } from '@/utils/app';
import { Context as SettingsContext } from '@/components/layouts/default';

interface Props extends BaseComponentProps {
  className?: string;
  duration?: number;
  delay?: number;
}

function Fade({ className = ``, duration = 1, delay = 0.2, children }: Props) {
  const container = useRef<HTMLDivElement>(null);
  const settingsCtx = useContext(SettingsContext);

  useEffect(() => {
    if (!settingsCtx.fade) return;
    if (!app.structure.canAnimate) return;
    gsap.from(container.current, {
      autoAlpha: 0,
      duration,
      delay,
      ease: Power1.easeIn,
    });
  }, [app.structure.canAnimate, settingsCtx]);

  return (
    <div ref={container} className={`fade ${className}`}>
      {children}
    </div>
  );
}

export type { Props };
export default Fade;
