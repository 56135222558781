import React, { useMemo } from 'react';
import { HorizontalSectionProps, Image } from '@/types';
import HorizontalSection from '@/components/modules/HorizontalSection';
import Parallax from '@/components/animations/Parallax';
import ImageWrapper from '@/components/modules/ImageWrapper';
import classNames from 'classnames';

interface Props extends HorizontalSectionProps {
  entrySeparator: Image;
  images: Image[];
  text: string;
  first?: boolean;
  last?: boolean;
}

function EmergentEntry({
  containerAnimation,
  trigger,
  background,
  separator,
  entrySeparator,
  mobileRatio,
  desktopRatio,
  images = [],
  text,
  first = false,
  last = false,
}: Props) {
  const firstImage = useMemo<Image>(
    () => (images.length > 0 ? images[0] : null),
    [images]
  );

  const secondImage = useMemo<Image>(
    () => (images.length > 0 ? images[1] : null),
    [images]
  );

  return (
    <div className="relative z-1">
      <HorizontalSection
        containerAnimation={containerAnimation}
        trigger={trigger}
        background={background}
        separator={separator}
        mobileRatio={mobileRatio}
        desktopRatio={desktopRatio}
      >
        {first && (
          <Parallax
            className="emergent__entry-separator absolute top-0 left-[12vh] bottom-0 z-1 lg:left-[30vh]"
            containerAnimation={containerAnimation}
            trigger={trigger}
            movement={-20}
            horizontal
          >
            <ImageWrapper
              className="h-full"
              data={entrySeparator.data}
              layout="fixed"
              alt=""
            />
          </Parallax>
        )}
        <div
          className={classNames(
            `emergent-entry__content absolute inset-0 flex items-end`,
            {
              'pr-5': !last,
              'pr-5 lg:pr-16': last,
            }
          )}
        >
          <div className="emergent-entry__image h-full flex-1">
            <ImageWrapper
              className="h-full w-full"
              data={firstImage?.data}
              alt={firstImage?.alt}
            />
          </div>
          <div className="emergent-entry__text-image h-3/4 w-[66.66vh] flex">
            <div className="emergent-entry__image h-full w-[33.33vh]">
              <ImageWrapper
                className="h-full"
                data={secondImage?.data}
                alt={secondImage?.alt}
              />
            </div>
            <div className="flex-1 px-4">
              <p className="font-light text-wine-red text-[2.125vh] leading-[2.5vh] lg:text-[2vh] lg:leading-[2.33vh]">
                {text}
              </p>
            </div>
          </div>
        </div>
        {!last && (
          <div className="emergent__entry-separator absolute top-0 right-4 bottom-0 z-1">
            <ImageWrapper
              className="h-full"
              data={entrySeparator.data}
              layout="fixed"
              alt=""
            />
          </div>
        )}
      </HorizontalSection>
    </div>
  );
}

export type { Props };
export default EmergentEntry;
