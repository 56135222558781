import { useCallback, useMemo, useReducer } from 'react';
import navigationReducer, {
  ActionKind,
  initialState,
} from '@/reducers/navigationReducer';
import { Navigation, SectionRange } from '@/types';

function useNavigation(items: Navigation) {
  const [state, dispatch] = useReducer(navigationReducer, initialState);

  const navigation = useMemo<Navigation>(
    () => state.navigation,
    [state.navigation]
  );
  const navigationReady = useMemo<boolean>(() => state.ready, [state.ready]);
  const sectionsRanges = useMemo<SectionRange>(
    () => state.sectionsRanges,
    [state.sectionsRanges]
  );

  const getSectionRanges = useCallback(() => {
    dispatch({
      type: ActionKind.GET_SECTION_RANGE,
      payload: { startSection: `products`, endSection: `emergent` },
    });
    dispatch({
      type: ActionKind.GET_SECTION_RANGE,
      payload: { startSection: `designers`, endSection: `team` },
    });
  }, []);

  const initNavigation = useCallback(() => {
    dispatch({
      type: ActionKind.CALC_START_POINTS,
      payload: items,
    });
    getSectionRanges();
    dispatch({ type: ActionKind.SET_IS_READY });
  }, [items]);

  return { navigationReady, navigation, sectionsRanges, initNavigation };
}

export default useNavigation;
