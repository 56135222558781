import React from 'react';

interface Props {
  className: string;
  text: string;
}

const Characters = React.forwardRef<HTMLParagraphElement, Props>(
  ({ className, text = `` }, ref) => (
    <p ref={ref} className={`${className || ``}`}>
      {text
        ?.split(``)
        .reverse()
        .map((char, i) => (
          <span key={`${char}-${i}`} className="inline-block">
            {char}
          </span>
        ))}
    </p>
  )
);

export default Characters;
