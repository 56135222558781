import React, { useRef } from 'react';
import { Image, SectionProps } from '@/types';
import Product, { Props as ProductProps } from '@/components/modules/Product';
import Fade from '@/components/animations/Fade';

interface Props extends SectionProps {
  background: Image;
  items: ProductProps[];
}

function ProductsBody({ name, background, items = [] }: Props) {
  const section = useRef<HTMLDivElement>(null);
  return (
    <section id={name} ref={section} className="products-body">
      {items.map((product) => (
        <Fade key={product.id}>
          <Product background={background} {...product} />
        </Fade>
      ))}
    </section>
  );
}

export type { Props };
export default ProductsBody;
