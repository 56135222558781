import React from 'react';
import { INavigationItem } from '@/types';
import './style.scss';
import classNames from 'classnames';

interface Props extends INavigationItem {
  active?: boolean;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function MenuItem({ name, label, startPoint, active = false, onClick }: Props) {
  return (
    <a
      key={name}
      className={classNames(`menu-item`, {
        'hover:drop-shadow-md': !active,
      })}
      href={`?section=${name}`}
      data-anchor={name}
      data-start-point={startPoint}
      onClick={onClick}
    >
      <div
        className={classNames(
          `text-white text-center bg-red min-w-31 pl-7 pr-10 py-2 transition-all duration-500`,
          {
            'hover:bg-white hover:text-red': !active,
            'bg-iceberg text-eerie-black': active,
          }
        )}
      >
        {label}
      </div>
    </a>
  );
}

export type { Props };
export default MenuItem;
