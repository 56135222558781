import React from 'react';
import { INavigationItem } from '@/types';
import classNames from 'classnames';

interface Props extends INavigationItem {
  active?: boolean;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function SideMenuItem({
  name,
  label,
  startPoint,
  active = false,
  onClick,
}: Props) {
  return (
    <a
      key={name}
      href={`?section=${name}`}
      className={classNames(
        `side-menu-item text-20 text-wine-red leading-31 pl-4 pr-7 pt-1 first:pt-0 pb-1 last:pb-0 border-b last:border-0 border-wine-red block transition-all duration-500`,
        {
          'text-white bg-wine-red': active,
        }
      )}
      data-anchor={name}
      data-start-point={startPoint}
      onClick={onClick}
    >
      {label}
    </a>
  );
}

export default SideMenuItem;
