import React, { useContext, useMemo, useRef } from 'react';
import { Context as HorizontalScrollingContext } from '@/components/modules/HorizontalScrolling';
import HorizontalSection from '@/components/modules/HorizontalSection';
import { HorizontalSectionProps } from '@/types';
import AnimatedArrow from '@/components/animations/AnimatedArrow';
import ParallaxFloatElement from '@/components/modules/ParallaxFloatElement';
import HomeArrow from '@/assets/images/arrows/home-arrow.svg';
import HomeCurves from '@/components/modules/HomeCurves';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import Fade from '@/components/animations/Fade';
import { Context as SettingsContext } from '@/components/layouts/default';
import ImageWrapper from '@/components/modules/ImageWrapper';

export interface Props
  extends Omit<HorizontalSectionProps, 'containerAnimation' | 'trigger'> {
  firstText?: string;
  secondText?: string;
  thirdText?: string;
  iosFallbackImage?: IGatsbyImageData;
}

type StaticData = {
  diverDesktop: FileNode;
};

const query = graphql`
  query Data {
    diverDesktop: file(name: { eq: "diver" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

function Home({
  name,
  background,
  separator,
  mobileRatio,
  desktopRatio,
  firstText,
  secondText,
  thirdText,
  iosFallbackImage,
}: Props) {
  const section = useRef<HTMLDivElement>(null);
  const containerAnimation = useContext<React.MutableRefObject<GSAPAnimation>>(
    HorizontalScrollingContext
  );
  const settingsCtx = useContext(SettingsContext);

  const { diverDesktop } = useStaticQuery<StaticData>(query);

  const fallbackText = useMemo(
    () => `${firstText} ${secondText} ${thirdText}`,
    [firstText, secondText, thirdText]
  );

  const elements = useMemo(
    () => [
      {
        item: {
          id: 1,
          name: `diver`,
          image: { data: getImage(diverDesktop) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
    ],
    []
  );

  return (
    <HorizontalSection
      ref={section}
      name={name}
      containerAnimation={containerAnimation}
      trigger={section}
      background={background}
      separator={separator}
      mobileRatio={mobileRatio}
      desktopRatio={desktopRatio}
    >
      <div className="home relative text-right w-full h-full flex">
        {settingsCtx.homeCurves ? (
          <Fade delay={1}>
            <HomeCurves
              containerAnimation={containerAnimation}
              trigger={section}
              firstText={firstText}
              secondText={secondText}
              thirdText={thirdText}
              delay={1}
            />
          </Fade>
        ) : (
          <div className="home__fallback w-[60vh] md:w-3/4 absolute left-0 bottom-0 transform -translate-x-1/4 md:transform-none">
            <ImageWrapper data={iosFallbackImage} alt={fallbackText} />
          </div>
        )}
        <div className="home__elements-group absolute bottom-0 right-[45vh] md:right-[40vh]">
          <div className="relative">
            <AnimatedArrow
              className="home__arrow absolute w-[50vh] bottom-0 -left-1/2 text-eerie-black hidden lg:block"
              containerAnimation={containerAnimation}
              trigger={section}
              delay={1}
              horizontal
            >
              <HomeArrow className="fill-current w-full h-full" />
            </AnimatedArrow>
            <Fade delay={1}>
              <ParallaxFloatElement
                className="home__astronaut relative z-20 w-[25.96vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                horizontal
                movement={-60}
                maxRadius={elements[0].item.maxRadius}
                maxSpeed={elements[0].item.maxSpeed}
                data={elements[0].item.image.data}
                alt="diver"
              />
            </Fade>
          </div>
        </div>
      </div>
    </HorizontalSection>
  );
}

export default Home;
