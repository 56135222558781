import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import favicon from '@/static/favicon.ico';
import { handleUploadUrl } from '@/utils/strapi';
import { SImage } from '@/types/strapi';

const siteUrl = process.env.GATSBY_URL;

interface Props {
  title?: string;
  defaultTitle: string;
  description?: string;
  defaultDescription: string;
  image?: SImage;
  defaultImage: SImage;
  twitterUsername?: string;
  article?: boolean;
}

function SEO({
  title,
  defaultTitle,
  description,
  defaultDescription,
  image,
  defaultImage,
  twitterUsername,
  article = false,
}: Props) {
  const { pathname } = useLocation();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${handleUploadUrl(image?.url) || defaultImage.url}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <link rel="icon" href={favicon} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {article && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
}

export default SEO;

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
