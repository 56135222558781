import React from 'react';
import { Link, SocialLink } from '@/types';
import IconLink from '@/components/modules/IconLink';

export interface Props {
  icons: SocialLink[];
  link: Link;
}

function Footer({ icons, link }: Props) {
  return (
    <footer className="footer w-screen fixed bottom-0 left-0 p-7 z-50 flex justify-center items-center">
      <div className="footer__socials flex flex-col sm:flex-row gap-4 justify-center items-center">
        {icons.map((icon) => (
          <IconLink key={icon.type} className="w-5 h-5" {...icon} />
        ))}
        <a
          className="w-fit hidden lg:inline-block font-df-camino text-26 leading-32"
          href={link.url}
        >
          {link.label}
        </a>
      </div>
    </footer>
  );
}

export default Footer;
