import React, { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Background, INavigationItem, SectionProps } from '@/types';
import DesignersGrid from '@/components/modules/DesignersGrid';
import { Props as DesignersGridItemProps } from '@/components/modules/DesignersGridItem';
import DesignersMenu from '@/components/modules/DesignersMenu';
import ImageWrapper from '@/components/modules/ImageWrapper';
import Parallax from '@/components/animations/Parallax';
import Line from '@/assets/images/arrows/designers-line-arrow.svg';
import SideMenu from '@/components/modules/SideMenu';
import useSectionMenu from '@/hooks/useSectionMenu';
import Fade from '@/components/animations/Fade';

interface Props extends SectionProps {
  background: Background;
  gridItems: DesignersGridItemProps[];
  navigationItems: INavigationItem[];
  startPoint: number;
  endPoint: number;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function DesignersHead({
  name,
  background,
  gridItems,
  navigationItems = [],
  startPoint,
  endPoint,
  onClick,
}: Props) {
  const section = useRef<HTMLDivElement>(null);
  const [
    root,
    sectionTrigger,
    createTrigger,
    isMenuVisible,
    isSideMenuOpen,
    handleSideMenuToggle,
    handleSideMenuClick,
    currentSubSection,
  ] = useSectionMenu(startPoint, endPoint);

  useLayoutEffect(() => {
    if (!startPoint || !endPoint) return;
    if (sectionTrigger) sectionTrigger.kill();
    createTrigger();
  }, [startPoint, endPoint]);

  return (
    <section
      id={name}
      ref={section}
      className="designers-head relative h-fit w-screen pt-30 lg:pt-60"
    >
      <Fade delay={background.delay}>
        <div className="designers-head__bg absolute inset-0 overflow-x-hidden">
          <Parallax
            className="h-full"
            trigger={section}
            movement={100}
            horizontal
          >
            <ImageWrapper
              data={background.data}
              mobile={background.mobile}
              className="h-full"
              alt=""
            />
          </Parallax>
        </div>
      </Fade>
      <Fade delay={0.6}>
        <div className="container pr-18 lg:pr-0">
          <DesignersGrid items={gridItems} onClick={onClick} />
        </div>
      </Fade>
      <Line className="absolute bottom-0 translate-y-1/2 z-10 left-0 right-0" />
      {root &&
        createPortal(
          <>
            <DesignersMenu
              navigationItems={navigationItems}
              currentSection={currentSubSection}
              isVisible={isMenuVisible}
              onClick={onClick}
            />
            <SideMenu
              label="designers"
              items={navigationItems}
              open={isSideMenuOpen}
              isVisible={isMenuVisible}
              onToggle={handleSideMenuToggle}
              onClick={handleSideMenuClick}
            />
          </>,
          root
        )}
    </section>
  );
}

export type { Props };
export default DesignersHead;
