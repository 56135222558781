import { Background, HomeData, SectionSeparator } from '@/types';
import { getImage } from 'gatsby-plugin-image';
import { useMemo } from 'react';

function useHomeData({
  strapiMainSeo,
  strapiMenu,
  strapiHome,
  strapiMarchio,
  strapiManifesto,
  strapiIdeazione,
  strapiProdotti,
  strapiTeam,
  strapiEmersivi,
  strapiDesigner,
}: HomeData) {
  const seo = useMemo(() => strapiMainSeo, [strapiMainSeo]);

  const navigationItems = useMemo(
    () =>
      strapiMenu.items.map((el) => ({
        label: el.name,
        name: el.sectionId,
      })),
    [strapiMenu]
  );

  const home = useMemo(
    () => ({
      backgrounds: [
        {
          data: getImage(strapiHome.backgrounds[0].desktop.localFile),
          mobile: getImage(strapiHome.backgrounds[0].mobile.localFile),
          parallaxMovement: 30,
          delay: 0.2,
        },
        {
          data: getImage(strapiHome.backgrounds[1].desktop.localFile),
          mobile: getImage(strapiHome.backgrounds[1].mobile.localFile),
          parallaxMovement: -60,
          delay: 1,
        },
        {
          data: getImage(strapiHome.backgrounds[2].desktop.localFile),
          mobile: getImage(strapiHome.backgrounds[2].mobile.localFile),
          parallaxMovement: -40,
          delay: 0.6,
        },
      ] as Background[],
      firstText: strapiHome.firstText,
      secondText: strapiHome.secondText,
      thirdText: strapiHome.thirdText,
      iosFallbackImage: getImage(strapiHome?.iosFallbackImage?.localFile),
      separator: {
        data: getImage(strapiHome.separator.desktop.localFile),
        mobile: getImage(strapiHome.separator.mobile.localFile),
        position: `left`,
        parallaxMovement: -20,
        delay: 0.4,
      } as SectionSeparator,
    }),
    [strapiHome]
  );

  const marchio = useMemo(
    () => ({
      backgrounds: [
        {
          data: getImage(strapiMarchio.backgrounds[0].desktop.localFile),
          mobile: getImage(strapiMarchio.backgrounds[0].mobile.localFile),
          parallaxMovement: -30,
          delay: 0.2,
        },
        {
          data: getImage(strapiMarchio.backgrounds[1].desktop.localFile),
          mobile: getImage(strapiMarchio.backgrounds[1].mobile.localFile),
          parallaxMovement: 60,
          delay: 0.6,
        },
        {
          data: getImage(strapiMarchio.backgrounds[2].desktop.localFile),
          mobile: getImage(strapiMarchio.backgrounds[2].mobile.localFile),
          parallaxMovement: -60,
          delay: 1,
        },
      ],
      separator: {
        data: getImage(strapiMarchio.separator.desktop.localFile),
        mobile: getImage(strapiMarchio.separator.mobile.localFile),
        position: `center`,
        parallaxMovement: 20,
        delay: 0.8,
      } as SectionSeparator,
    }),
    [strapiMarchio]
  );

  const manifesto = useMemo(
    () => ({
      backgrounds: [
        {
          data: getImage(strapiManifesto.backgrounds[0].desktop.localFile),
          mobile: getImage(strapiManifesto.backgrounds[0].mobile.localFile),
          alt: strapiManifesto.backgrounds[0].desktop.alternativeText,
          parallaxMovement: 20,
          delay: 0.2,
        },
        {
          data: getImage(strapiManifesto.backgrounds[1].desktop.localFile),
          mobile: getImage(strapiManifesto.backgrounds[1].mobile.localFile),
          alt: strapiManifesto.backgrounds[1].desktop.alternativeText,
          parallaxMovement: 120,
          delay: 0.6,
        },
        {
          data: getImage(strapiManifesto.backgrounds[2].desktop.localFile),
          mobile: getImage(strapiManifesto.backgrounds[2].mobile.localFile),
          alt: strapiManifesto.backgrounds[2].desktop.alternativeText,
          parallaxMovement: -70,
          delay: 1,
        },
      ],
      separator: {
        data: getImage(strapiManifesto.separator.desktop.localFile),
        mobile: getImage(strapiManifesto.separator.mobile.localFile),
        alt: strapiManifesto.separator.desktop.alternativeText,
        position: `center`,
        parallaxMovement: 20,
        delay: 0.8,
      } as SectionSeparator,
      content: strapiManifesto.content.data.content,
    }),
    [strapiManifesto]
  );

  const ideazione = useMemo(
    () => ({
      backgrounds: [
        {
          data: getImage(strapiIdeazione.backgrounds[0].desktop.localFile),
          mobile: getImage(strapiIdeazione.backgrounds[0].mobile.localFile),
          parallaxMovement: 20,
          delay: 0.2,
        },
        {
          data: getImage(strapiIdeazione.backgrounds[1].desktop.localFile),
          mobile: getImage(strapiIdeazione.backgrounds[1].mobile.localFile),
          parallaxMovement: 120,
          delay: 0.6,
        },
      ],
      separator: {
        data: getImage(strapiIdeazione.separator.desktop.localFile),
        mobile: getImage(strapiIdeazione.separator.mobile.localFile),
        position: `center`,
        parallaxMovement: 20,
        delay: 0.8,
      } as SectionSeparator,
      entries: strapiIdeazione.founders.map((el) => ({
        image: { data: getImage(el.image.localFile), alt: `` },
        title: el.name,
        columns: [el.column1.data.column1, el.column2.data.column2],
        alignEnd: el.alignEnd,
      })),
    }),
    [strapiIdeazione]
  );

  const prodotti = useMemo(
    () => ({
      backgrounds: [
        {
          data: getImage(strapiProdotti.backgrounds[0].desktop.localFile),
          mobile: getImage(strapiProdotti.backgrounds[0].mobile.localFile),
          parallaxMovement: 120,
          delay: 0.2,
        },
        {
          data: getImage(strapiProdotti.backgrounds[1].desktop.localFile),
          mobile: getImage(strapiProdotti.backgrounds[1].mobile.localFile),
          parallaxMovement: 65,
          delay: 0.6,
        },
      ],
      cover: {
        data: getImage(strapiProdotti.coverImage.desktop.localFile),
        mobile: getImage(strapiProdotti.coverImage.mobile.localFile),
      },
      bodyBackground: {
        data: getImage(strapiProdotti.backgrounds[2].desktop.localFile),
        mobile: getImage(strapiProdotti.backgrounds[2].mobile.localFile),
      },
      items: strapiProdotti.products.map((el) => ({
        coverImage: {
          data: getImage(el.coverImage.localFile),
          alt: el.title,
        },
        id: el.id,
        title: el.title,
        author: el.author,
        year: el.year,
        description: el.description.data.description,
        links: el.links,
        socials: el.socials,
        productUrl: el.productUrl,
        productImage: {
          data: getImage(el.productImage.localFile),
          alt: el.title,
        },
        productGallery: el.productGallery.map((i) => ({
          data: getImage(i.localFile),
          alt: ``,
        })),
      })),
    }),
    [strapiProdotti]
  );

  const team = useMemo(
    () => ({
      members: strapiTeam.members.map((el) => ({
        title: el.title,
        description: el.description,
        image: getImage(el.image.localFile),
        alt: el.image.alternativeText,
        textLeft: el.textLeft,
        separator: el.separator,
      })),
      backgrounds: [
        {
          data: getImage(strapiTeam.backgrounds[0].desktop.localFile),
          mobile: getImage(strapiTeam.backgrounds[0].mobile.localFile),
          alt: strapiTeam.backgrounds[0].desktop.alternativeText,
          delay: 0.2,
        },
        {
          data: getImage(strapiTeam.backgrounds[1].desktop.localFile),
          mobile: getImage(strapiTeam.backgrounds[1].mobile.localFile),
          alt: strapiTeam.backgrounds[1].desktop.alternativeText,
          delay: 0.6,
        },
      ],
      separator: {
        data: getImage(strapiTeam.separator.desktop.localFile),
        mobile: getImage(strapiTeam.separator.mobile.localFile),
        alt: strapiTeam.separator.desktop.alternativeText,
        delay: 1,
      },
    }),
    [strapiTeam]
  );

  const emersivi = useMemo(
    () => ({
      backgrounds: [
        {
          data: getImage(strapiEmersivi.backgrounds[0].desktop.localFile),
          mobile: getImage(strapiEmersivi.backgrounds[0].mobile.localFile),
          parallaxMovement: -70,
          delay: 0.2,
        },
        {
          data: getImage(strapiEmersivi.backgrounds[1].desktop.localFile),
          mobile: getImage(strapiEmersivi.backgrounds[1].mobile.localFile),
          parallaxMovement: 120,
          delay: 0.6,
        },
      ],
      separator: {
        data: getImage(strapiEmersivi.separator.desktop.localFile),
        mobile: getImage(strapiEmersivi.separator.mobile.localFile),
        parallaxMovement: -20,
        position: `center`,
        delay: 1,
      } as SectionSeparator,
      headingLogo: {
        data: getImage(strapiEmersivi.headingLogo.localFile),
        alt: `emersivi`,
      },
      content: strapiEmersivi.content.data.content,
      elements: strapiEmersivi.illustration.map((el, i) => ({
        data: getImage(el.localFile),
        parallaxMovement: -40 * i,
      })),
      oddBackgrounds: [
        {
          data: getImage(strapiEmersivi.oddBackgrounds[0].desktop.localFile),
          mobile: getImage(strapiEmersivi.oddBackgrounds[0].mobile.localFile),
          parallaxMovement: -120,
        },
        {
          data: getImage(strapiEmersivi.oddBackgrounds[1].desktop.localFile),
          mobile: getImage(strapiEmersivi.oddBackgrounds[1].mobile.localFile),
          parallaxMovement: -20,
        },
      ],
      entriesSeparator: {
        data: getImage(strapiEmersivi.entriesSeparator.desktop.localFile),
        mobile: getImage(strapiEmersivi.entriesSeparator.mobile.localFile),
        parallaxMovement: -20,
      },
      entries: strapiEmersivi.entries.map((el) => ({
        images: [
          {
            data: getImage(el.image1.localFile),
            alt: ``,
          },
          {
            data: getImage(el.image2.localFile),
            alt: ``,
          },
        ],
        text: el.text.data.text,
      })),
    }),
    [strapiEmersivi]
  );

  const designers = useMemo(
    () => ({
      gridItems: strapiDesigner.grid.map((el) => ({
        image: el.image ? getImage(el.image.localFile) : undefined,
        name: el.name || undefined,
      })),
      list: strapiDesigner.designers.map((el) => ({
        label: el.label,
        name: el.name,
        portrait: { data: getImage(el.portrait.localFile), alt: `` },
        hint: el.description.data.description,
        images: el.images.map((i) => ({
          data: getImage(i.localFile),
          alt: ``,
        })),
      })),
      background: {
        data: getImage(strapiDesigner.background.desktop.localFile),
        mobile: getImage(strapiDesigner.background.mobile.localFile),
        alt: ``,
        delay: 0.2,
      },
    }),
    [strapiDesigner]
  );

  return {
    seo,
    navigationItems,
    home,
    marchio,
    manifesto,
    ideazione,
    prodotti,
    team,
    emersivi,
    designers,
  };
}

export default useHomeData;
