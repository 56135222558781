import React, { useCallback, useContext, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import MenuTrackingLine from '@/components/modules/MenuTrackingLine';
import ArrowLinks from '@/components/modules/ArrowLinks';
import { INavigationItem } from '@/types';
import Logo from '@/assets/images/logo.svg';
import { calcScrollStartPoint } from '@/utils/navigation';
import { Context as SectionObserverContext } from '@/components/modules/SectionObserver';

interface Props {
  navigation: INavigationItem[];
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

const friction = 2;

function DesktopMenu({ navigation, onClick }: Props) {
  const sectionObserverCtx = useContext(SectionObserverContext);
  const header = useRef<HTMLHeadElement>(null);
  const logo = useRef<HTMLAnchorElement>(null);
  const masterTl = useRef<GSAPTimeline>(gsap.timeline());
  const headerTl = useRef<GSAPTimeline>(null);

  const handleMouseEnter = useCallback((timeline: GSAPTimeline) => {
    if (!timeline) return;

    gsap.to(timeline, {
      duration: friction,
      timeScale: 0,
      overwrite: true,
    });
  }, []);

  const handleMouseLeave = useCallback((timeline: GSAPTimeline) => {
    if (!timeline) return;

    gsap.to(timeline, {
      duration: friction,
      timeScale: -1,
      overwrite: true,
    });
  }, []);

  useLayoutEffect(() => {
    const offset = 50;

    headerTl.current = gsap.timeline({
      scrollTrigger: {
        start: () => `+=${calcScrollStartPoint(`products`)} bottom`,
        end: `+=700`,
        scrub: 2,
        invalidateOnRefresh: true,
      },
    });

    headerTl.current
      .to(
        header.current,
        {
          y: () =>
            -(window.innerHeight / 2 - header.current.scrollHeight / 2) +
            offset,
        },
        0
      )
      .to(
        logo.current,
        {
          y: 0,
          scale: 0.6,
        },
        0
      );

    masterTl.current
      .from(
        logo.current,
        {
          duration: 0.6,
          scale: 0.75,
          ease: `back.out(1.4)`,
          immediateRender: false,
        },
        1
      )
      .from(
        logo.current,
        {
          duration: 1,
          autoAlpha: 0,
        },
        1
      );

    return () => {
      masterTl.current.kill();
    };
  }, []);

  return (
    <header
      ref={header}
      className="fixed top-1/2 -translate-y-1/2 w-full z-[60]"
    >
      <nav className="desktop-menu hidden relative w-full lg:block">
        <ArrowLinks
          navigation={navigation}
          currentSection={sectionObserverCtx.currentSection}
          onClick={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <a
          ref={logo}
          href="/"
          className="desktop-menu__logo absolute top-1/2 left-1/2 w-40 h-40 transform -translate-y-1/2 -translate-x-1/2"
          onClick={onClick}
        >
          <Logo />
        </a>
        <MenuTrackingLine timeline={masterTl.current} />
      </nav>
    </header>
  );
}

export default DesktopMenu;
