import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { INavigationItem } from '@/types';
import classNames from 'classnames';
import ProductsMenuItem from '@/components/modules/ProductsMenuItem';
import { app } from '@/utils/app';

interface Props {
  navigationItems: INavigationItem[];
  currentSection: string;
  isVisible: boolean;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function ProductsMenu({
  navigationItems = [],
  currentSection,
  isVisible,
  onClick,
}: Props) {
  const nav = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!app.structure.navigationReady) return;
    const logo = document.querySelector(`.desktop-menu__logo`);
    const offset = 6;

    gsap.set(nav.current, {
      y: () => logo.clientHeight + offset,
    });
  }, [app.structure.navigationReady]);

  return (
    <nav
      ref={nav}
      className={classNames(
        `products-menu hidden fixed top-0 left-1/2 z-40 max-w-100 w-full transform -translate-x-1/2 lg:flex lg:items-start lg:flex-wrap lg:gap-x-3`,
        {
          'fade-out pointer-events-none': !isVisible,
          'fade-in': isVisible,
        }
      )}
    >
      {navigationItems.map((item, i) => (
        <ProductsMenuItem
          key={item.name}
          {...item}
          left={i % 2 === 0}
          active={item.name === currentSection}
          onClick={onClick}
        />
      ))}
    </nav>
  );
}

export default ProductsMenu;
