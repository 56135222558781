import React from 'react';
import { Image } from '@/types';
import ImageWrapper from '@/components/modules/ImageWrapper';

export interface FounderEntryProps {
  image: Image;
  title: string;
  columns: string[];
  alignEnd: boolean;
}

function FounderEntry({ image, title, columns, alignEnd }) {
  return (
    <div className="founder-entry h-[37vh]">
      <div className="founder-entry__content flex gap-x-[2.8vh]">
        <div className="founder-entry__image w-[29vh]">
          <ImageWrapper
            data={image.data}
            mobile={image.mobile}
            alt={image.alt}
          />
        </div>
        {columns.map((content, i) => (
          <div
            key={`col-${i}`}
            className={`founder-entry__content-col w-[29vh] text-wine-red ${
              alignEnd ? `flex flex-col justify-end` : ``
            }`}
          >
            {i === 0 && (
              <div className="founder-entry__title text-[2.66vh]">{title}</div>
            )}
            <div className="text-[2.11vh] font-light">{content}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FounderEntry;
