import React from 'react';
import { INavigationItem } from '@/types';
import DesignersMenuItem from '@/components/modules/DesignersMenuItem';
import classNames from 'classnames';

interface Props {
  navigationItems: INavigationItem[];
  currentSection: string;
  isVisible: boolean;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function DesignersMenu({
  navigationItems,
  currentSection,
  isVisible,
  onClick,
}: Props) {
  return (
    <nav
      className={classNames(
        `designers-menu hidden fixed top-30 z-10 h-fit space-y-1 lg:flex lg:flex lg:flex-col lg:items-end lg:top-60 lg:right-[15vw] xl:right-[18.61vw]`,
        {
          'fade-out pointer-events-none': !isVisible,
          'fade-in': isVisible,
        }
      )}
    >
      {navigationItems.map((item) => (
        <DesignersMenuItem
          key={item.name}
          {...item}
          active={item.name === currentSection}
          onClick={onClick}
        />
      ))}
    </nav>
  );
}

export default DesignersMenu;
