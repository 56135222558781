export const snakeToCamel = (str: string): string =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace(`-`, ``).replace(`_`, ``)
    );

export const snakeKeysToCamel = (data) =>
  Object.keys(data).reduce((obj, key) => {
    obj[snakeToCamel(key)] = data[key];
    return obj;
  }, {});

export const setOffScreen = (
  element: HTMLDivElement,
  horizontal: boolean
): number =>
  horizontal
    ? window.screen.width - element.offsetWidth / 2
    : window.screen.height - element.offsetWidth / 2;

export const iOS = () =>
  [
    `iPad Simulator`,
    `iPhone Simulator`,
    `iPod Simulator`,
    `iPad`,
    `iPhone`,
    `iPod`,
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes(`Mac`) && `ontouchend` in document);
