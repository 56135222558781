import React, {
  useCallback,
  useState,
  useMemo,
  useLayoutEffect,
  createContext,
} from 'react';
import SectionObserver from '@/components/modules/SectionObserver';
import { BaseComponentProps, Link, Navigation, SocialLink } from '@/types';
import DesktopMenu from '@/components/modules/DesktopMenu';
import MobileMenu from '@/components/modules/MobileMenu';
import Footer from '@/components/modules/Footer';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { iOS } from '@/utils/helpers';
import { app } from '@/utils/app';

interface Props extends BaseComponentProps {
  navigation: Navigation;
  icons: SocialLink[];
  footerLink: Link;
  scrollTo: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    value: string
  ) => void | React.MouseEventHandler<HTMLAnchorElement>;
}

export const Context = createContext(null);

function DefaultLayout({
  navigation,
  icons,
  footerLink,
  children,
  scrollTo,
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isIos, setIsIos] = useState(false);
  const settings = useMemo(
    () => ({
      appear: !isIos,
      parallax: !isIos,
      fade: !isIos,
      float: !isIos,
      homeCurves: !isIos,
    }),
    [isIos]
  );

  const handleToggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);

    if (!isMenuOpen) {
      disableBodyScroll(document.body);
      return;
    }
    enableBodyScroll(document.body);
  }, [isMenuOpen]);

  const handleMenuTransitionEnd = useCallback(
    (section: string) => {
      if (!section) return;
      scrollTo(null, section);
    },
    [scrollTo]
  );

  useLayoutEffect(() => {
    setIsIos(iOS());
  }, []);

  return (
    <Context.Provider value={settings}>
      <SectionObserver navigation={navigation} scrollTo={scrollTo}>
        {app.structure.navigationReady && (
          <>
            <MobileMenu
              navigation={navigation.main}
              opened={isMenuOpen}
              onToggle={handleToggleMenu}
              onClick={handleToggleMenu}
              onTransitionEnd={handleMenuTransitionEnd}
              scrollTo={scrollTo as React.MouseEventHandler<HTMLAnchorElement>}
            />
            <DesktopMenu navigation={navigation.main} onClick={scrollTo} />
          </>
        )}
        <main className="overflow-hidden">{children}</main>
        <Footer icons={icons} link={footerLink} />
      </SectionObserver>
    </Context.Provider>
  );
}

export type { Props };
export default DefaultLayout;
