import { INavigationItem, Navigation, SectionRange } from '@/types';
import { getSectionRange, map } from '@/utils/navigation';

type State = {
  ready: boolean;
  navigation: Navigation;
  sectionsRanges: SectionRange;
};

export enum ActionKind {
  CALC_START_POINTS = `CALC_START_POINTS`,
  GET_SECTION_RANGE = `GET_SECTION_RANGE`,
  SET_IS_READY = `SET_IS_READY`,
}

type Action = {
  type: ActionKind;
  payload?: any;
};

export const initialState: State = {
  ready: false,
  navigation: {},
  sectionsRanges: {},
};

const navigationReducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionKind.CALC_START_POINTS:
      return {
        ...state,
        navigation: {
          main: map(payload.main),
          designers: map(
            payload.designers.map(
              ({ label, name }): Omit<INavigationItem, `startPoint`> => ({
                label,
                name,
                parent: `designers`,
              })
            )
          ),
          products: map(
            payload.products.map(
              ({ title, id }): Omit<INavigationItem, `startPoint`> => ({
                label: title,
                name: id,
                parent: `products`,
              })
            )
          ),
        },
      };
    case ActionKind.GET_SECTION_RANGE:
      return {
        ...state,
        sectionsRanges: {
          ...state.sectionsRanges,
          ...{
            [payload.startSection]: getSectionRange(
              state.navigation?.main,
              payload.startSection,
              payload.endSection
            ),
          },
        },
      };
    case ActionKind.SET_IS_READY:
      return {
        ...state,
        ready: true,
      };
    default:
      throw new Error(`Selected action doesn't exist on ${type}`);
  }
};

export default navigationReducer;
