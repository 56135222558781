import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { BaseComponentProps } from '@/types';
import { app } from '@/utils/app';

interface Props extends BaseComponentProps {
  className?: string;
  elements: React.ReactNode[];
  containerAnimation?: React.MutableRefObject<GSAPAnimation>;
  trigger: React.MutableRefObject<HTMLDivElement>;
  speed?: number;
  stagger?: number;
  loop?: boolean;
  yoyo?: boolean;
  scroll?: boolean;
  horizontal?: boolean;
  markers?: boolean;
}

function MotionPath({
  className,
  elements,
  containerAnimation,
  trigger,
  speed = 5,
  stagger,
  loop = true,
  yoyo = false,
  scroll = false,
  horizontal = false,
  markers,
  children,
}: Props) {
  const container = useRef(null);

  useLayoutEffect(() => {
    if (!app.structure.canAnimate) return;
    if (!elements.length) {
      console.error(`No elements provided for being animated`);
      return;
    }

    const isHorizontal = horizontal
      ? { containerAnimation: containerAnimation?.current }
      : {};

    const mode = scroll
      ? {
          scrollTrigger: {
            ...isHorizontal,
            trigger: trigger.current,
            scrub: 1,
            start: `top top`,
            markers,
          },
        }
      : {};

    const path =
      container.current.nextElementSibling.firstChild.querySelector(`.path`);

    const motion = gsap
      .timeline({
        defaults: {
          duration: speed,
          ease: `none`,
          yoyo: !scroll && yoyo,
          repeat: !scroll && loop ? -1 : 0,
          transformOrigin: `center`,
        },
        ...mode,
      })
      .to(gsap.utils.toArray(`.anim`, container.current), {
        stagger: stagger || 0,
        motionPath: {
          path,
          align: path,
          alignOrigin: [0.5, 0.5],
        },
        immediateRender: true,
      });

    return () => {
      if (motion.timeline) motion.timeline.kill();
    };
  }, [app.structure.canAnimate]);

  return (
    <div className={`motion-path w-min ${className || ``}`}>
      <div className="motion-path__content relative">
        <div ref={container} className="motion-path__animated">
          {elements.map((el, i) => (
            <div key={`element-${i}`}>{el}</div>
          ))}
        </div>
        <div className="motion-path__content">{children}</div>
      </div>
    </div>
  );
}

export default MotionPath;
