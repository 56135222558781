import React from 'react';
import './style.scss';

export interface ParagraphsGroupProps {
  content: string;
}

function ParagraphsGroup({ content }) {
  return (
    <div className="paragraphs-group w-full font-df-camino font-light text-wine-red">
      {content}
    </div>
  );
}

export default ParagraphsGroup;
