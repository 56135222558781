import React from 'react';
import { SocialLink } from '@/types';
import Facebook from '@/assets/icons/facebook.svg';
import Instagram from '@/assets/icons/instagram.svg';
import Linkedin from '@/assets/icons/linkedin.svg';
import WhatsApp from '@/assets/icons/whatsapp.svg';
import LinkedinFilled from '@/assets/icons/linkedin-filled.svg';

const icon: { [key: string]: JSX.Element } = {
  facebook: <Facebook className="fill-current w-full h-full" />,
  instagram: <Instagram className="fill-current w-full h-full" />,
  linkedin: <Linkedin className="fill-current w-full h-full" />,
  whatsapp: <WhatsApp className="fill-current w-full h-full" />,
  linkedinFilled: <LinkedinFilled className="fill-current w-full h-full" />,
};

interface Props extends SocialLink {
  className?: string;
}

function IconLink({ className, type, url }: Props) {
  return (
    <a
      className={`${className || ``} inline-block`}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {icon[type]}
    </a>
  );
}

export type { Props };
export default IconLink;
