import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import MenuItem from '@/components/modules/MenuItem';
import { INavigationItem } from '@/types';
import horizontalLoop from '@/utils/gsap/horizontal-loop';

interface Props {
  navigation: INavigationItem[];
  currentSection: string;
  onMouseEnter: (timeline: GSAPTimeline) => void;
  onMouseLeave: (timeline: GSAPTimeline) => void;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

const ease = `M0,0 C0.126,0.382 0.282,0.674 0.44,0.822 0.579,0.952 0.724,1.046 0.848,0.997 0.906,0.974 0.949,1 1,1`;

function ArrowLinks({
  navigation,
  currentSection,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: Props) {
  const container = useRef<HTMLDivElement>(null);
  const loop = useRef<GSAPTimeline>(null);

  const handleAnimationComplete = useCallback(() => {
    const items: HTMLDivElement[] = gsap.utils.toArray(`.arrow-links__items`);

    loop.current = horizontalLoop(items, {
      repeat: -1,
      speed: 0.7,
      reversed: true,
      paddingRight: parseFloat(
        gsap.getProperty(items[0], `marginRight`, `px`) as string
      ),
    });
  }, []);

  useLayoutEffect(() => {
    const containerGap = 20;

    gsap.set(container.current, {
      x: () => -(container.current.scrollWidth * 2) - containerGap,
    });

    const appear = gsap.to(container.current, {
      duration: 7,
      x: 0,
      ease: CustomEase.create(`custom`, ease),
      onComplete: handleAnimationComplete,
    });

    return () => {
      appear.kill();
      loop.current.kill();
    };
  }, []);

  return (
    <div ref={container} className="arrow-links relative z-1 flex">
      <div className="arrow-links__items [flex:0_0_100vw] xl:px-10">
        <div
          className="flex justify-evenly xl:justify-between"
          onMouseEnter={() => onMouseEnter(loop.current)}
          onMouseLeave={() => onMouseLeave(loop.current)}
        >
          {navigation.map(({ name, startPoint, label }) => (
            <MenuItem
              key={name}
              name={name}
              label={label}
              startPoint={startPoint}
              active={name === currentSection}
              onClick={onClick}
            />
          ))}
        </div>
      </div>
      <div className="arrow-links__items [flex:0_0_100vw] xl:px-10">
        <div
          className="flex justify-evenly xl:justify-between"
          onMouseEnter={() => onMouseEnter(loop.current)}
          onMouseLeave={() => onMouseLeave(loop.current)}
        >
          {navigation.map(({ name, startPoint, label }) => (
            <MenuItem
              key={name}
              name={name}
              label={label}
              startPoint={startPoint}
              active={name === currentSection}
              onClick={onClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ArrowLinks;
