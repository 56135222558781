import React from 'react';
import { Background, SectionProps, SectionSeparator } from '@/types';
import TeamSeparator from '@/components/modules/TeamSeparator';
import TeamMember, {
  Props as TeamMemberProps,
} from '@/components/modules/TeamMember';
import ImageWrapper from '@/components/modules/ImageWrapper';
import Fade from '@/components/animations/Fade';

export interface Props extends SectionProps {
  elements: TeamMemberProps[];
  background: Background[];
  separator: SectionSeparator;
}

function Team({ name, elements = [], background, separator }: Props) {
  return (
    <section
      id={name}
      className="team relative text-right h-screen p-6 pl-[20vw] md:pl-60 flex justify-center items-center"
    >
      <div className="absolute top-0 left-0 w-full h-full -z-10">
        <Fade delay={background[0].delay}>
          <ImageWrapper
            data={background[0].data}
            className="absolute top-0 left-0 h-full"
            alt=""
          />
        </Fade>

        <Fade delay={background[1].delay}>
          <ImageWrapper
            data={background[1].data}
            className="absolute top-0 left-0 h-full"
            layout="fixed"
            alt=""
          />
        </Fade>

        <Fade delay={separator.delay}>
          <ImageWrapper
            data={separator.data}
            className="absolute left-0 top-0 h-full"
            layout="fixed"
            alt=""
          />
        </Fade>
      </div>
      <div className="w-full h-full grid auto-cols-[minmax(0px,_fit-content)] grid-flow-col grid-rows-[minmax(0,_350px)_min-content] content-center pl-55 gap-y-4 md:gap-y-8 gap-x-20">
        {elements.map((item, index) => (
          <React.Fragment key={item.title}>
            <TeamMember
              title={item.title}
              alt={item.alt}
              description={item.description}
              image={item.image}
            />
            {item.separator && item.separator !== `none` && (
              <TeamSeparator
                key={index.toString()}
                large={item.separator === `large`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <Fade delay={1}>
        <div className="w-full h-full grid auto-cols-[250px] grid-flow-col grid-rows-[minmax(0,_350px)_min-content] content-center gap-y-4 md:gap-y-8 gap-x-20">
          {elements.map((item) => (
            <TeamMember
              key={item.title}
              title={item.title}
              alt={item.alt}
              description={item.description}
              image={item.image}
            />
          ))}
        </div>
      </Fade>
    </section>
  );
}

export default Team;
