import React, { useCallback, useContext, useMemo, useState } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Context as SectionObserverContext } from '@/components/modules/SectionObserver';
import useScrollNavigation from './useScrollNavigation';

function useSectionMenu(
  startPoint: number,
  endPoint: number,
  markers?: boolean
): any {
  const [sectionTrigger, setSectionTrigger] = useState<ScrollTrigger>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const sectionObserverCtx = useContext(SectionObserverContext);
  const [scrollTo] = useScrollNavigation(2);

  const root = useMemo(
    () =>
      typeof document !== `undefined`
        ? document.getElementById(`___gatsby`)
        : null,
    []
  );

  const currentSubSection = useMemo(
    () => sectionObserverCtx.currentSubSection,
    [sectionObserverCtx.currentSubSection]
  );

  const handleToggle = useCallback(({ isActive }) => {
    if (isActive) {
      setIsMenuVisible(true);
      return;
    }
    setIsMenuVisible(false);
  }, []);

  const createTrigger = useCallback(() => {
    const trigger = ScrollTrigger.create({
      start: `+=${startPoint - 1} top`,
      end: `${endPoint} bottom`,
      onToggle: handleToggle,
      markers,
    });
    setSectionTrigger(trigger);
  }, [startPoint, endPoint]);

  const handleSideMenuToggle = useCallback(() => {
    setIsSideMenuOpen(!isSideMenuOpen);
  }, [isSideMenuOpen]);

  const handleSideMenuClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      setIsSideMenuOpen(false);
      scrollTo(e, ``);
    },
    []
  );

  return [
    root,
    sectionTrigger,
    createTrigger,
    isMenuVisible,
    isSideMenuOpen,
    handleSideMenuToggle,
    handleSideMenuClick,
    currentSubSection,
  ];
}

export default useSectionMenu;
