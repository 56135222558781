import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseComponentProps, Navigation } from '@/types';
import { set, get, reset } from '@/utils/sections';
import { useDebounce } from '@/hooks/useDebounce';
import { getSubSections } from '@/utils/navigation';
import { app } from '@/utils/app';

interface Props extends BaseComponentProps {
  navigation: Navigation;
  scrollTo: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    value: string
  ) => void;
}

export const Context = React.createContext({
  currentSection: ``,
  currentSubSection: ``,
});

function SectionObserver({ navigation, scrollTo, children }: Props) {
  const [section, setSection] = useState<string>(``);
  const [subSection, setSubSection] = useState<string>(``);

  const contextValue = useMemo(
    () => ({ currentSection: section, currentSubSection: subSection }),
    [section, subSection]
  );

  const sections = useMemo(() => navigation.main, [navigation]);
  const subSections = useMemo(() => getSubSections(navigation), [navigation]);

  const handleActiveSection = useCallback(() => {
    const y = window.scrollY;

    const currentSection = sections
      .filter((item) => item.startPoint <= y)
      .at(-1);

    const currentSubSection = subSections
      .filter(
        (item) => item.startPoint <= y && item.parent === currentSection.name
      )
      .at(-1);

    if (!currentSection) {
      reset(`section`);
      setSection(``);
      return;
    }

    if (!currentSubSection) {
      reset(`subsection`);
      setSubSection(``);
    }

    set(`section`, currentSection?.name);
    if (currentSubSection) set(`subsection`, currentSubSection?.name);
    setSection(currentSection?.name);
    setSubSection(currentSubSection?.name);
  }, [navigation]);

  const debounceHandleActiveSection = useDebounce(handleActiveSection, 20);

  useEffect(() => {
    if (!navigation || !app.structure.navigationReady) return;

    window.addEventListener(`scroll`, debounceHandleActiveSection, {
      passive: true,
    });

    return () => {
      window.removeEventListener(`scroll`, debounceHandleActiveSection);
    };
  }, [app.structure.navigationReady, navigation]);

  useEffect(() => {
    if (!app.structure.navigationReady) return;
    const currentSection = get(`section`);
    const currentSubSection = get(`subsection`);
    scrollTo(null, currentSubSection || currentSection);
  }, [app.structure.navigationReady]);

  return (
    <Context.Provider value={contextValue}>
      <div className="section-observer">{children}</div>
    </Context.Provider>
  );
}

export default SectionObserver;
