import React, { useLayoutEffect, useRef, useContext } from 'react';
import { gsap } from 'gsap';
import { BaseComponentProps } from '@/types';
import { app } from '@/utils/app';
import { Context as SettingsContext } from '@/components/layouts/default';

interface Props extends BaseComponentProps {
  className?: string;
  containerAnimation?: React.MutableRefObject<GSAPAnimation>;
  trigger: React.MutableRefObject<HTMLDivElement>;
  duration?: number;
  from?: GSAPTweenVars;
  to?: GSAPTweenVars;
  path?: string;
  markers?: boolean;
  horizontal?: boolean;
}

function Appear({
  className,
  containerAnimation,
  trigger,
  duration = 1,
  from,
  to,
  path,
  horizontal,
  markers = false,
  children,
}: Props) {
  const container = useRef<HTMLDivElement>(null);
  const settingsCtx = useContext(SettingsContext);

  useLayoutEffect(() => {
    if (!settingsCtx.appear) return;
    if (!app.structure.canAnimate) return;
    if (!trigger) {
      console.error(`trigger has not been provided`);
      return;
    }

    const isHorizontal = horizontal
      ? { containerAnimation: containerAnimation?.current }
      : null;

    const appear = gsap.timeline({
      defaults: {
        duration,
      },
      scrollTrigger: {
        ...isHorizontal,
        trigger: container.current,
        start: `top bottom`,
        end: `bottom top`,
        onLeave: () => appear.reverse(),
        onEnterBack: () => appear.play(),
        onLeaveBack: () => appear.reverse(),
        onUpdate: () => appear.scrollTrigger.refresh(),
        markers,
      },
    });

    if (!path) {
      appear.fromTo(container.current, from, {
        ...to,
      });
    } else {
      appear.to(container.current, {
        motionPath: {
          path,
          alignOrigin: [0.5, 0.5],
        },
      });
    }

    return () => {
      appear.kill();
    };
  }, [app.structure.canAnimate, settingsCtx]);

  return (
    <div ref={container} className={`appear w-fit ${className || ``}`}>
      {children}
    </div>
  );
}

export default Appear;
