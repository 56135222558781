import React from 'react';
import ImageWrapper from '@/components/modules/ImageWrapper';
import IconLink, {
  Props as IconLinkProps,
} from '@/components/modules/IconLink';
import { Image, Link } from '@/types';
import ReactMarkdown from 'react-markdown';

export interface Props {
  id: string;
  background?: Image;
  coverImage?: Image;
  title: string;
  author: string;
  year: string;
  description: string;
  links: Link[];
  socials: IconLinkProps[];
  productUrl: string;
  productImage?: Image;
  productGallery: Image[];
}

function Product({
  id,
  background,
  coverImage,
  title,
  author,
  year,
  description,
  links = [],
  socials = [],
  productUrl,
  productImage,
  productGallery = [],
}: Props) {
  return (
    <div
      id={id}
      className="product relative z-1 w-full flex flex-col justify-start items-center pt-50 last:pb-50"
    >
      <div className="product__cover relative z-1 w-full max-w-[800px] hidden justify-center items-center pr-14 md:flex lg:pr-0">
        <ImageWrapper data={coverImage.data} alt={coverImage.alt} />
      </div>
      <div className="product__content relative font-light text-left text-matisse w-full min-h-102 flex flex-col justify-between items-center pt-12 px-4 pb-4 -mt-6 lg:px-0">
        <div className="product__background hidden absolute inset-0 md:block">
          <ImageWrapper
            className="w-full h-full"
            data={background.data}
            alt=""
          />
        </div>
        <div className="w-full relative max-w-[800px] flex flex-col justify-center items-start pr-18 lg:pr-0">
          <p className="title w-full text-30 leading-36">{title}</p>
          <p className="creator w-full text-19 leading-23 mb-4">
            {author}, {year}
          </p>
          <ReactMarkdown className="description w-full text-19 leading-23">
            {description}
          </ReactMarkdown>
        </div>
        <div className="w-full relative max-w-[800px] flex flex-col justify-center items-start pl-2 mt-6 border-l-[1px] border-matisse border-solid">
          <div className="w-max flex flex-col gap-2 mb-4 text-18 lg:text-16 leading-22 lg:leading-20 text-matisse font-df-camino">
            {links.map(({ url, label }, i) => (
              <a key={`link-${i}`} href={url}>
                {label}
              </a>
            ))}
          </div>
          <div className="text-matisse flex items-center gap-x-2">
            {socials.map((social) => (
              <IconLink
                key={social.type}
                className="w-12px h-12px"
                {...social}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="product__buy-btn relative w-full max-w-[880px]">
        <div className="hidden absolute bottom-0 right-[12%] translate-y-1/2 sm:block">
          <a
            href={productUrl}
            className="font-df-camino font-light text-17 leading-21 text-white bg-metallic-seaweed h-11 w-36 rounded-full flex items-center justify-center"
            type="button"
          >
            Buy
          </a>
        </div>
      </div>
      <div className="product__image hidden w-full max-w-[800px] justify-center items-center mt-12 pr-18 md:flex lg:pr-0">
        <ImageWrapper
          className="w-full"
          data={productImage.data}
          alt={productImage.alt}
        />
      </div>
      <div className="product__gallery w-full max-w-[800px] flex flex-col pl-4 pr-18 space-y-4 mt-12 md:hidden">
        {productGallery.map(({ data, alt }, i) => (
          <ImageWrapper
            key={`image-${i}`}
            className="w-full"
            data={data}
            alt={alt}
          />
        ))}
      </div>
    </div>
  );
}

export default Product;
