import React from 'react';

export interface Props {
  large: boolean;
}

function TeamSeparator({ large }: Props) {
  return (
    <div
      className={`row-span-2 ${
        large ? `w-[200px] lg:w-[220px]` : `w-[150px] lg:w-[160px]`
      }`}
    >
      &nbsp;
    </div>
  );
}

export default TeamSeparator;
