import React, {
  createContext,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { BaseComponentProps } from '@/types';
import { gsap } from 'gsap';
import './style.scss';
import { useDebounce } from '@/hooks/useDebounce';
import { app } from '@/utils/app';

interface Props extends BaseComponentProps {
  id: string;
  index: number;
}

const Context = createContext(null);

function HorizontalScrolling({ id, index, children }: Props) {
  const container = useRef<HTMLDivElement>(null);
  const scroll = useRef<GSAPAnimation>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  const checkIfScrollable = useCallback(() => {
    const scrollOffset =
      container.current.scrollWidth - document.documentElement.clientWidth;

    if (!disabled && scrollOffset <= 0) {
      scroll.current.scrollTrigger.disable();
      setDisabled(true);
      return;
    }

    scroll.current.scrollTrigger.enable();
    setDisabled(false);
  }, []);

  const debounceCheckIfScrollable = useDebounce(checkIfScrollable, 100);

  useLayoutEffect(() => {
    setTimeout(() => {
      scroll.current = gsap.to(container.current, {
        x: () =>
          `${-(
            container.current.scrollWidth - document.documentElement.clientWidth
          )}px`,
        ease: `none`,
        scrollTrigger: {
          trigger: container.current,
          invalidateOnRefresh: true,
          pin: true,
          scrub: true,
          end: () => `+=${container.current.scrollWidth}`,
        },
      });
      app.structure.initHorizontalScrolling(id);
      checkIfScrollable();
      window.addEventListener(`resize`, debounceCheckIfScrollable);
    }, index * 2000);

    return () => {
      scroll.current.scrollTrigger.kill();
      window.removeEventListener(`resize`, debounceCheckIfScrollable);
    };
  }, []);

  return (
    <Context.Provider value={scroll}>
      <div id={id} className="horizontal-scrolling__anchor invisible" />
      <div
        ref={container}
        className="horizontal-scrolling flex overscroll-none"
        data-anchor={id}
      >
        {children}
      </div>
    </Context.Provider>
  );
}

export { Context };
export default HorizontalScrolling;
