import React, { useLayoutEffect, useRef, useContext } from 'react';
import { BaseComponentProps } from '@/types';
import { gsap } from 'gsap';
import { moveX, moveY, rotate } from '@/utils/float';
import { random } from '@/utils/math';
import { Context as SettingsContext } from '@/components/layouts/default';

interface Props extends BaseComponentProps {
  className?: string;
  maxRadius?: number;
  maxSpeed?: number;
}

function Float({ className, maxRadius = 30, maxSpeed = 10, children }: Props) {
  const container = useRef<HTMLDivElement>(null);
  const settingsCtx = useContext(SettingsContext);

  useLayoutEffect(() => {
    if (!settingsCtx.float) return;
    const randomX = random(10, maxRadius);
    const randomY = random(20, maxRadius);
    const randomTime = random(3, maxSpeed);
    const randomTime2 = random(3, maxSpeed);
    const randomAngle = random(-10, 10);

    const float = gsap.set(container.current, {
      x: randomX(-1),
      y: randomX(1),
      rotation: randomAngle(-1),
    });

    moveX(container.current, randomX, randomTime(), 1);
    moveY(container.current, randomY, randomTime2(), -1);
    rotate(container.current, randomAngle, randomTime(), 1);

    return () => {
      float.kill();
    };
  }, [settingsCtx]);

  return (
    <div ref={container} className={`float w-fit ${className || ``}`}>
      {children}
    </div>
  );
}

export type { Props };
export default React.memo(Float);
