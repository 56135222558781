import React, { useMemo, useRef } from 'react';
import { INavigationItem } from '@/types';
import Arrow from '@/assets/images/arrows/side-menu-arrow.svg';
import SideMenuItem from '@/components/modules/SideMenuItem';
import classNames from 'classnames';

interface Props {
  label: string;
  items: INavigationItem[];
  open: boolean;
  isVisible: boolean;
  onToggle: () => void;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function SideMenu({ label, items, open, isVisible, onToggle, onClick }: Props) {
  const content = useRef<HTMLDivElement>(null);
  const style = useMemo<React.CSSProperties>(
    () => ({
      width: open ? `${content.current?.clientWidth}px` : 0,
    }),
    [content.current, open]
  );

  return (
    <div
      className={classNames(
        `side-menu fixed top-30 right-0 z-40 max-h-[calc(100vh-10rem)] flex lg:hidden`,
        {
          'fade-out pointer-events-none': !isVisible,
          'fade-in': isVisible,
        }
      )}
    >
      <button
        className="side-menu__btn relative font-light text-24 text-white leading-33 w-11 h-fit bg-wine-red pt-6 pl-1 pb-18"
        type="button"
        onClick={onToggle}
      >
        <span className="inline-block transform -rotate-90 before:pt-[100%] before:float-left">
          {label}
        </span>
        <Arrow className="fill-current absolute top-0 left-[35%] h-full" />
      </button>
      <nav
        style={style}
        className="side-menu__navigation bg-white overflow-hidden transition-all duration-300"
      >
        <div
          ref={content}
          className="side-menu__navigation-content h-full w-max py-2 border border-wine-red overflow-y-scroll"
        >
          {items.map((item) => (
            <SideMenuItem key={item.name} {...item} onClick={onClick} />
          ))}
        </div>
      </nav>
    </div>
  );
}

export default SideMenu;
