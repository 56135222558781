import React from 'react';
import { INavigationItem } from '@/types';
import classNames from 'classnames';

interface Props extends INavigationItem {
  active?: boolean;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function DesignersMenuItem({
  label,
  name,
  parent,
  startPoint,
  active = false,
  onClick,
}: Props) {
  return (
    <a
      key={name}
      href={`?section=${parent}&subsection=${name}`}
      className={classNames(
        `designers-menu-item font-light text-24 leading-30 w-fit px-1 transition-all duration-500 hover:bg-wine-red hover:text-white`,
        {
          'text-wine-red': !active,
          'bg-wine-red text-white': active,
        }
      )}
      data-anchor={name}
      data-start-point={startPoint}
      onClick={onClick}
    >
      {label}
    </a>
  );
}

export type { Props };
export default DesignersMenuItem;
