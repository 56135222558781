import React, { useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import classNames from 'classnames';

interface Props {
  timeline: GSAPTimeline;
  speed?: number;
  dashTotal?: number;
  slide?: boolean;
}

function MenuTrackingLine({
  timeline,
  speed = 4,
  dashTotal = 200,
  slide = false,
}: Props) {
  useLayoutEffect(() => {
    timeline.from(`.menu-tracking-line__dash`, {
      duration: 0.01,
      autoAlpha: 0,
      stagger: 0.01,
    });

    if (!slide) return;
    const dashWidth = 9;
    const dashSpacing = 7;

    gsap.set(`.menu-tracking-line__dash`, {
      x: (i) => i * (dashWidth + dashSpacing),
    });

    timeline.to(`.menu-tracking-line__dash`, {
      duration: speed,
      ease: `none`,
      x: () => `+=${window.innerWidth}`,
      modifiers: {
        x: gsap.utils.unitize((x: string) => parseFloat(x) % window.innerWidth),
      },
      repeat: -1,
    });
  }, [timeline]);

  return (
    <div className="menu-tracking-line absolute top-1/2 transform-gpu -translate-y-1/2 w-full">
      <div
        className={classNames(`relative -left-2 h-1`, {
          ' flex gap-x-[7px]': !slide,
        })}
      >
        {[...Array(dashTotal)].map((_, i) => (
          <div
            key={`dash-${i}`}
            className={classNames(
              `menu-tracking-line__dash h-1 bg-eerie-black`,
              {
                'absolute w-9px': slide,
                '[flex:0_0_0.625rem]': !slide,
              }
            )}
          />
        ))}
      </div>
    </div>
  );
}

export default MenuTrackingLine;
