export const set = (key: string, target: string) => {
  const url = new URL(window.location.href);
  const section = url.searchParams.get(key);
  if (section === target) return;
  if (section !== target) url.searchParams.delete(key);
  url.searchParams.append(key, target);
  window.history.replaceState(``, ``, url.href);
};

export const get = (key: string): string => {
  const url = new URL(window.location.href);
  return url.searchParams.get(key);
};

export const reset = (key: string) => {
  const url = new URL(window.location.href);
  const section = url.searchParams.get(key);
  if (!section) return;
  url.searchParams.delete(key);
  window.history.replaceState(``, ``, url.href);
};
