import React, { useCallback, useEffect, useState } from 'react';
import { INavigationItem } from '@/types';
import classNames from 'classnames';

interface Props {
  navigation: INavigationItem[];
  opened: boolean;
  onClick: () => void;
  onTransitionEnd: (section: string) => void;
}

function MobileNavigation({
  navigation,
  opened,
  onClick,
  onTransitionEnd,
}: Props) {
  const [section, setSection] = useState<string>(null);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      setSection(e.currentTarget.getAttribute(`data-anchor`));
      onClick();
    },
    [onClick]
  );

  useEffect(() => {
    if (opened) setSection(null);
  }, [opened]);

  return (
    <nav
      className={classNames(
        `mobile-navigation fixed top-0 left-0 flex flex-col justify-start items-start py-20 w-screen h-screen overflow-auto bg-white z-10 transform -translate-y-full transition-transform duration-500`,
        { 'translate-y-0': opened }
      )}
      onTransitionEnd={() => onTransitionEnd(section)}
    >
      {navigation.map(({ label, name, startPoint }) => (
        <a
          key={name}
          className="w-full px-7 py-4 text-24 leading-28 block first:border-t border-b border-wine-red"
          href={`?section=${name}`}
          data-anchor={name}
          data-start-point={startPoint}
          onClick={handleClick}
        >
          {label}
        </a>
      ))}
    </nav>
  );
}

export default MobileNavigation;
