import { gsap } from 'gsap';
import { Sine } from 'gsap/gsap-core';

export const rotate = (
  target: HTMLDivElement,
  angle: (direction: number) => number,
  speed: number,
  direction: number
) => {
  gsap.to(target, speed, {
    rotation: angle(direction),
    ease: Sine.easeInOut,
    onComplete: () => rotate(target, angle, speed, 1),
    onCompleteParams: [target, direction * -1],
  });
};

export const moveX = (
  target: HTMLDivElement,
  x: (direction: number) => number,
  speed: number,
  direction: number
) => {
  gsap.to(target, speed, {
    x: x(direction),
    ease: Sine.easeInOut,
    onComplete: () => moveX(target, x, speed, 1),
    onCompleteParams: [target, direction * -1],
  });
};

export const moveY = (
  target: HTMLDivElement,
  y: (direction: number) => number,
  speed: number,
  direction: number
) => {
  gsap.to(target, speed, {
    y: y(direction),
    ease: Sine.easeInOut,
    onComplete: () => moveY(target, y, speed, 1),
    onCompleteParams: [target, direction * -1],
  });
};
