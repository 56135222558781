import React, { useCallback } from 'react';
import { gsap } from 'gsap';

function useScrollNavigation(duration = 1) {
  const scrollTo = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, urlAnchor = ``) => {
      e?.preventDefault();

      const target =
        e?.currentTarget ||
        document.querySelector(`[data-anchor="${urlAnchor}"]`);

      gsap.to(window, {
        scrollTo: {
          y: parseInt(target?.getAttribute(`data-start-point`), 10) + 1 || 0,
          autoKill: false,
        },
        duration,
      });
    },
    []
  );

  return [scrollTo];
}

export default useScrollNavigation;
