import { useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useDebounce } from '@/hooks/useDebounce';
import useNavigation from '@/hooks/useNavigation';
import { HorizontalScrolling } from '@/types';

const initialState: HorizontalScrolling = {
  'first-horizontal-section': false,
  'second-horizontal-section': false,
  'third-horizontal-section': false,
};

function useLoadStructure(data) {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [loadedHorizontalScrolling, setLoadedHorizontalScrolling] =
    useState<HorizontalScrolling>(initialState);
  const { navigationReady, navigation, sectionsRanges, initNavigation } =
    useNavigation(data.navigation);

  const canAnimate = useMemo<boolean>(
    () => ready && !isMounted,
    [ready, isMounted]
  );

  const handleRefreshInit = useCallback(() => {
    setIsMounted(true);
    setReady(false);
  }, []);

  const handleRefresh = useCallback(() => setReady(true), []);
  const debounceHandleRefreshInit = useDebounce(handleRefreshInit, 50);
  const debounceHandleRefresh = useDebounce(handleRefresh, 50);

  const initHorizontalScrolling = useCallback((id: string) => {
    const obj = {};
    obj[id] = true;
    setLoadedHorizontalScrolling((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    if (!ready) return;
    ScrollTrigger.addEventListener(`refreshInit`, debounceHandleRefreshInit);
    ScrollTrigger.addEventListener(`refresh`, debounceHandleRefresh);

    return () => {
      ScrollTrigger.removeEventListener(
        `refreshInit`,
        debounceHandleRefreshInit
      );
      ScrollTrigger.removeEventListener(`refreshInit`, debounceHandleRefresh);
    };
  }, [ready]);

  useEffect(() => {
    setReady(
      Object.keys(loadedHorizontalScrolling).every(
        (key) => loadedHorizontalScrolling[key]
      )
    );
  }, [loadedHorizontalScrolling]);

  useEffect(() => {
    if (ready) initNavigation();
  }, [ready]);

  return {
    loadedHorizontalScrolling,
    isMounted,
    ready,
    canAnimate,
    navigationReady,
    navigation,
    sectionsRanges,
    initHorizontalScrolling,
  };
}

export default useLoadStructure;
