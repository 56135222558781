import React, { useContext, useMemo, useRef } from 'react';
import { HorizontalSectionProps } from '@/types';
import HorizontalSection from '@/components/modules/HorizontalSection';
import FounderEntry, {
  FounderEntryProps,
} from '@/components/modules/FounderEntry';
import { Context as HorizontalScrollingContext } from '@/components/modules/HorizontalScrolling';
import ParallaxFloatElement from '@/components/modules/ParallaxFloatElement';
import Appear from '@/components/animations/Appear';
import IdeationArrow from '@/assets/images/arrows/ideation-arrow.svg';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import Fade from '@/components/animations/Fade';

export type Props = { entries: FounderEntryProps[] } & Omit<
  HorizontalSectionProps,
  'containerAnimation' | 'trigger'
>;

type StaticData = {
  foglia: FileNode;
  uomoRazzo: FileNode;
};

const query = graphql`
  query {
    foglia: file(name: { eq: "ideazione-foglia-desktop" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    uomoRazzo: file(name: { eq: "ideazione-uomo-razzo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

function Ideation({
  name,
  background,
  separator,
  mobileRatio,
  desktopRatio,
  entries,
}: Props) {
  const data = useStaticQuery<StaticData>(query);
  const section = useRef<HTMLDivElement>(null);
  const containerAnimation = useContext<React.MutableRefObject<GSAPAnimation>>(
    HorizontalScrollingContext
  );
  const elements = useMemo(
    () => [
      {
        item: {
          id: 1,
          name: `leaf`,
          image: { data: getImage(data.foglia) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
      {
        item: {
          id: 2,
          name: `rocket-man`,
          image: { data: getImage(data.uomoRazzo) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
    ],
    []
  );

  return (
    <HorizontalSection
      ref={section}
      name={name}
      containerAnimation={containerAnimation}
      trigger={section}
      background={background}
      separator={separator}
      mobileRatio={mobileRatio}
      desktopRatio={desktopRatio}
    >
      <div className="ideation relative h-full flex justify-center items-center">
        <Fade delay={1}>
          <Appear
            className="absolute -top-[10vh] right-[20vh]"
            path="M24.4369 1C121.951 161.711 -69.8707 127.085 31.5792 297C88 387 94 453 37 556"
            containerAnimation={containerAnimation}
            trigger={section}
            duration={5}
            horizontal
          >
            <ParallaxFloatElement
              className="manifest__leaf w-[17.52vh]"
              containerAnimation={containerAnimation}
              trigger={section}
              horizontal
              movement={-80}
              maxRadius={elements[0].item.maxRadius}
              maxSpeed={elements[0].item.maxSpeed}
              data={elements[0].item.image.data}
              alt={elements[0].item.name}
            />
          </Appear>
        </Fade>
        <IdeationArrow className="w-fit h-screen translate-x-1/2" />
        <Fade delay={1}>
          <div className="ideation__entries relative z-10">
            <FounderEntry {...entries[0]} />
            <FounderEntry {...entries[1]} />
          </div>
        </Fade>
        <Fade delay={1}>
          <ParallaxFloatElement
            className="manifest-rocket-man absolute bottom-[2vh] right-0 z-20 w-[12vh] lg:-right-[2vh]"
            containerAnimation={containerAnimation}
            trigger={section}
            movement={-600}
            maxRadius={elements[1].item.maxRadius}
            maxSpeed={elements[1].item.maxSpeed}
            data={elements[1].item.image.data}
            alt={elements[1].item.name}
          />
        </Fade>
      </div>
    </HorizontalSection>
  );
}

export default Ideation;
