import React from 'react';
import { graphql } from 'gatsby';
import { HomeData } from '@/types';
import useLoadStructure from '@/hooks/useLoadStructure';
import useScrollNavigation from '@/hooks/useScrollNavigation';
import useHomeData from '@/hooks/useHomeData';
import { app } from '@/utils/app';
import Index from '@/components/pages';

type Props = {
  data: HomeData;
};

function Homepage({ data }: Props) {
  const {
    seo,
    navigationItems,
    home,
    designers,
    prodotti,
    ideazione,
    team,
    emersivi,
    manifesto,
    marchio,
  } = useHomeData(data);

  const [scrollTo] = useScrollNavigation(2);

  app.structure = useLoadStructure({
    navigation: {
      main: navigationItems,
      designers: designers.list,
      products: prodotti.items,
    },
  });

  return (
    <Index
      seo={seo}
      navigation={app.structure.navigation}
      footerIcons={[]}
      footerLink={{ label: ``, url: `` }}
      home={home}
      marchio={marchio}
      manifesto={manifesto}
      ideazione={ideazione}
      prodotti={prodotti}
      emersivi={emersivi}
      designers={designers}
      team={team}
      navigationReady={app.structure.navigationReady}
      scrollTo={scrollTo}
    />
  );
}

export const query = graphql`
  query {
    strapiMainSeo(locale: { eq: "en" }) {
      ...StrapiSeo
    }
    strapiMenu(locale: { eq: "en" }) {
      ...StrapiMenu
    }

    strapiHome(locale: { eq: "en" }) {
      ...StrapiHome
    }

    strapiMarchio(locale: { eq: "en" }) {
      ...StrapiMarchio
    }

    strapiManifesto(locale: { eq: "en" }) {
      ...StrapiManifesto
    }

    strapiIdeazione(locale: { eq: "en" }) {
      ...StrapiIdeazione
    }

    strapiProdotti(
      products: { elemMatch: { hidden: { eq: false } } }
      locale: { eq: "en" }
    ) {
      ...StrapiProdotti
    }

    strapiTeam(locale: { eq: "en" }) {
      ...StrapiTeam
    }

    strapiDesigner(locale: { eq: "en" }) {
      ...StrapiDesigner
    }

    strapiEmersivi(locale: { eq: "en" }) {
      ...StrapiEmersivi
    }
  }
`;

export default Homepage;
