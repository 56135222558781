import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import ImageWrapper from '@/components/modules/ImageWrapper';

export interface Props {
  title: string;
  image: IGatsbyImageData;
  alt: string;
  description: string;
  textLeft?: boolean;
  separator?: 'none' | 'small' | 'large';
}

function TeamMember({
  title,
  description,
  textLeft = false,
  image,
  alt,
}: Props) {
  return (
    <>
      <div className="w-full h-full overflow-hidden max-w-50">
        <ImageWrapper
          className="w-auto h-full ml-auto"
          data={image}
          objectFit="cover"
          objectPosition="center"
          alt={alt}
        />
      </div>
      <div
        className={`w-full h-full max-w-50 flex flex-col justify-start items-center text-wine-red ${
          textLeft ? `text-left` : `text-right`
        }`}
      >
        <p className="w-full font-df-camino text-18 leading-18 md:text-20 md:leading-24">
          {title}
        </p>
        <p className="w-full font-df-camino font-light whitespace-pre-line text-15 leading-15 md:text-16 md:leading-17">
          {description}
        </p>
      </div>
    </>
  );
}

export default TeamMember;
