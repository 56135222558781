import React, { useContext, useMemo, useRef } from 'react';
import { HorizontalSectionProps } from '@/types';
import { Context as HorizontalScrollingContext } from '@/components/modules/HorizontalScrolling';
import HorizontalSection from '@/components/modules/HorizontalSection';
import Appear from '@/components/animations/Appear';
import AnimatedArrow from '@/components/animations/AnimatedArrow';
import ImageWrapper from '@/components/modules/ImageWrapper';
import ParallaxFloatElement from '@/components/modules/ParallaxFloatElement';
import MotionPath from '@/components/animations/MotionPath';
import Arrow01 from '@/assets/images/arrows/brand-arrow-01.svg';
import Arrow02 from '@/assets/images/arrows/brand-arrow-02.svg';
import Arrow03 from '@/assets/images/arrows/brand-arrow-03.svg';
import FrogPath from '@/assets/images/motion-paths/frog-path.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import Fade from '@/components/animations/Fade';

export type Props = Omit<
  HorizontalSectionProps,
  'containerAnimation' | 'trigger'
>;

type StaticData = {
  handHarm: FileNode;
  fukudaTable: FileNode;
  butterflySmall: FileNode;
  butterflyBig: FileNode;
  frog: FileNode;
  astronaut: FileNode;
  lanzavecchia: FileNode;
  leaf: FileNode;
  juggler: FileNode;
};

const query = graphql`
  query {
    handHarm: file(name: { eq: "hand-harm" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    fukudaTable: file(name: { eq: "fukuda-table" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    butterflySmall: file(name: { eq: "butterfly-leaf-small" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    butterflyBig: file(name: { eq: "butterfly-leaf-big" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    frog: file(name: { eq: "frog" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    astronaut: file(name: { eq: "astronaut" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    lanzavecchia: file(name: { eq: "lanzavecchia" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    leaf: file(name: { eq: "leaf" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    juggler: file(name: { eq: "juggler" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

function Brand({
  name,
  background,
  separator,
  mobileRatio,
  desktopRatio,
}: Props) {
  const data = useStaticQuery<StaticData>(query);
  const elements = useMemo(
    () => [
      {
        item: {
          id: 1,
          name: `hand-harm`,
          image: { data: getImage(data.handHarm) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
      {
        item: {
          id: 2,
          name: `butterfly-big`,
          image: { data: getImage(data.butterflyBig) },
          maxRadius: 35,
          maxSpeed: 12,
        },
      },
      {
        item: {
          id: 3,
          name: `butterfly-small`,
          image: { data: getImage(data.butterflySmall) },
          maxRadius: 32,
          maxSpeed: 10,
        },
      },
      {
        item: {
          id: 4,
          name: `fukuda-table`,
          image: { data: getImage(data.fukudaTable) },
        },
      },
      {
        item: {
          id: 5,
          name: `juggler`,
          image: { data: getImage(data.juggler) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
      {
        item: {
          id: 6,
          name: `leaf`,
          image: { data: getImage(data.leaf) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
      {
        item: {
          id: 7,
          name: `frog`,
          image: { data: getImage(data.frog) },
        },
      },
      {
        item: {
          id: 9,
          name: `astronaut`,
          image: { data: getImage(data.astronaut) },
          maxRadius: 10,
          maxSpeed: 6,
        },
      },
      {
        item: {
          id: 10,
          name: `lanzavecchia`,
          image: { data: getImage(data.lanzavecchia) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
    ],
    []
  );
  const section = useRef<HTMLDivElement>(null);
  const containerAnimation = useContext<React.MutableRefObject<GSAPAnimation>>(
    HorizontalScrollingContext
  );

  return (
    <HorizontalSection
      ref={section}
      name={name}
      containerAnimation={containerAnimation}
      trigger={section}
      background={background}
      separator={separator}
      mobileRatio={mobileRatio}
      desktopRatio={desktopRatio}
    >
      <div className="brand relative text-right w-full h-full flex">
        <div className="brand__elements-group absolute top-[2vh] -left-[25vh] z-10">
          <div className="relative">
            <AnimatedArrow
              className="brand__animated-arrow-03 absolute top-1/2 left-[20%] w-full text-eerie-black lg:hidden"
              containerAnimation={containerAnimation}
              trigger={section}
              horizontal
              delay={1}
            >
              <Arrow03 className="fill-current" />
            </AnimatedArrow>
            <Fade delay={1}>
              <ParallaxFloatElement
                className="brand__hand-harm w-[46vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                horizontal
                movement={60}
                maxRadius={elements[0].item.maxRadius}
                maxSpeed={elements[0].item.maxSpeed}
                data={elements[0].item.image.data}
                alt={elements[0].item.name}
              />
            </Fade>
          </div>
        </div>
        <Fade delay={1}>
          <Appear
            className="absolute -top-[20vh] left-[40vh] lg:left-[50vh]"
            path="M24.4369 1C121.951 161.711 -69.8707 127.085 31.5792 297"
            containerAnimation={containerAnimation}
            trigger={section}
            duration={5}
            horizontal
          >
            <ParallaxFloatElement
              className="brand__leaf w-[11.67vh]"
              containerAnimation={containerAnimation}
              trigger={section}
              horizontal
              movement={-200}
              maxRadius={elements[5].item.maxRadius}
              maxSpeed={elements[5].item.maxSpeed}
              data={elements[5].item.image.data}
              alt={elements[5].item.name}
            />
          </Appear>
        </Fade>
        <div className="brand__elements-group absolute top-[22.58vh] right-[35vh] z-20">
          <div className="relative">
            <Fade delay={1}>
              <ParallaxFloatElement
                className="brand__lanzavecchia absolute right-0 w-[50vh] lg:w-[44vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                horizontal
                movement={60}
                maxRadius={elements[8].item.maxRadius}
                maxSpeed={elements[8].item.maxSpeed}
                data={elements[8].item.image.data}
                alt={elements[8].item.name}
              />
              <MotionPath
                className="brand__frog absolute -bottom-10 -left-10 lg:-left-12"
                elements={[
                  <div className="anim absolute top-10 -left-15 w-[16.30vh]">
                    <ImageWrapper
                      data={elements[6].item.image.data}
                      alt={elements[6].item.name}
                    />
                  </div>,
                ]}
                containerAnimation={containerAnimation}
                trigger={section}
                scroll
                horizontal
              >
                <FrogPath className="w-[30vh]" />
              </MotionPath>
            </Fade>
          </div>
        </div>
        <AnimatedArrow
          className="brand__animated-arrow-02 absolute top-0 right-[26vh] text-eerie-black w-[37.42vh] lg:right-[43vh]"
          containerAnimation={containerAnimation}
          trigger={section}
          horizontal
          reverse
          delay={1}
        >
          <Arrow02 className="fill-current w-full h-full" />
        </AnimatedArrow>
        <AnimatedArrow
          className="brand__animated-arrow-01 hidden absolute -bottom-[2vh] left-[3vh] text-eerie-black w-[13.91vh] lg:block"
          containerAnimation={containerAnimation}
          trigger={section}
          horizontal
          delay={1}
        >
          <Arrow01 className="fill-current w-full h-full" />
        </AnimatedArrow>
        <div className="brand__elements-group relative left-[10%] w-fit px-10 lg:left-[20%]">
          <Fade delay={1}>
            <Appear
              className="absolute bottom-[26vh] left-[2vh]"
              path="M13.8017 294C8.72058 276.776 20.2156 266.504 8.1042 224.402C-4.00722 182.299 1.69038 166.833 8.10424 155.663C35.7332 107.546 -8.40009 118.715 13.8017 80.0499C31.5631 49.1173 19.0644 8.73316 13.8017 1"
              containerAnimation={containerAnimation}
              trigger={section}
              duration={3}
              horizontal
            >
              <ParallaxFloatElement
                className="brand__butterfly-leaf-small w-[8.21vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                horizontal
                movement={-80}
                maxRadius={elements[2].item.maxRadius}
                maxSpeed={elements[2].item.maxSpeed}
                data={elements[2].item.image.data}
                alt={elements[2].item.name}
              />
            </Appear>
          </Fade>
          <Fade delay={1}>
            <Appear
              className="absolute bottom-[18vh] left-[8vh]"
              path="M12.0002 287C23.0002 262.5 -16.9361 231.277 12.0002 215C54.9036 190.867 2.50022 164.078 2.50002 142C2.49998 137.5 0.499969 124.5 35 96.5C45.9805 87.5883 67 72 35 53C24.3334 50.3333 -1.59996 26.1 24 0.5"
              containerAnimation={containerAnimation}
              trigger={section}
              duration={4.2}
              horizontal
            >
              <ParallaxFloatElement
                className="brand__butterfly-leaf-big z-30 w-[14vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                horizontal
                movement={32}
                maxRadius={elements[1].item.maxRadius}
                maxSpeed={elements[1].item.maxSpeed}
                data={elements[1].item.image.data}
                alt={elements[1].item.name}
              />
            </Appear>
          </Fade>
          <Fade
            className="brand__fukuda-table relative h-full left-1/2 z-20 transform -translate-x-1/2 w-[45vh]"
            delay={1}
          >
            <div className="absolute bottom-0">
              <ImageWrapper
                data={elements[3].item.image.data}
                alt={elements[3].item.name}
              />
            </div>
          </Fade>
          <Fade delay={1}>
            <ParallaxFloatElement
              className="brand__juggler absolute bottom-[4vh] right-0 w-[19.87vh]"
              containerAnimation={containerAnimation}
              trigger={section}
              horizontal
              movement={40}
              maxRadius={elements[4].item.maxRadius}
              maxSpeed={elements[4].item.maxSpeed}
              data={elements[4].item.image.data}
              alt={elements[4].item.name}
            />
          </Fade>
        </div>
        <Fade delay={1}>
          <ParallaxFloatElement
            className="brand__astronaut absolute bottom-0 right-0 z-20 w-[25.99vh] lg:right-[15vh]"
            containerAnimation={containerAnimation}
            trigger={section}
            horizontal
            movement={-15}
            maxRadius={elements[7].item.maxRadius}
            maxSpeed={elements[7].item.maxSpeed}
            data={elements[7].item.image.data}
            alt={elements[7].item.name}
          />
        </Fade>
      </div>
    </HorizontalSection>
  );
}

export default Brand;
