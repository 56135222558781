import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { BaseComponentProps } from '@/types';
import { app } from '@/utils/app';

interface Props extends BaseComponentProps {
  className?: string;
  containerAnimation?: React.MutableRefObject<GSAPAnimation>;
  trigger?: React.MutableRefObject<HTMLDivElement>;
  duration?: number;
  stagger?: number;
  delay?: number;
  horizontal?: boolean;
  reverse?: boolean;
  markers?: boolean;
}

function AnimatedArrow({
  className,
  containerAnimation,
  trigger,
  duration = 0.05,
  stagger = 0.05,
  delay = 0,
  horizontal = false,
  reverse = false,
  markers = false,
  children,
}: Props) {
  const arrow = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!app.structure.canAnimate) return;
    if (!trigger) {
      console.error(`trigger has not been passed!`);
      return;
    }

    const isHorizontal = horizontal
      ? { containerAnimation: containerAnimation?.current }
      : {};

    const dash = gsap.utils.toArray(`path`, arrow.current);
    const animatedArrow = gsap.from(reverse ? dash.reverse() : dash, {
      autoAlpha: 0,
      duration,
      stagger,
      delay,
      scrollTrigger: {
        ...isHorizontal,
        trigger: arrow.current,
        start: `top bottom`,
        end: `bottom top`,
        markers,
      },
    });

    return () => {
      animatedArrow.kill();
    };
  }, [app.structure.canAnimate]);

  return (
    <div ref={arrow} className={className}>
      {children}
    </div>
  );
}

export default AnimatedArrow;
