import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Hamburger from '@/components/modules/Hamburger';
import MobileNavigation from '@/components/modules/MobileNavigation';
import Logo from '@/assets/images/logo.svg';
import { calcScrollStartPoint } from '@/utils/navigation';
import { INavigationItem } from '@/types';
import { app } from '@/utils/app';

interface Props {
  navigation: INavigationItem[];
  opened: boolean;
  onToggle: () => void;
  onClick: () => void;
  onTransitionEnd: (section: string) => void;
  scrollTo: React.MouseEventHandler<HTMLAnchorElement>;
}

function MobileMenu({
  navigation,
  opened,
  onToggle,
  onClick,
  onTransitionEnd,
  scrollTo,
}: Props) {
  const logo = useRef<HTMLAnchorElement>(null);

  useLayoutEffect(() => {
    const logoOffset = 25;

    const timeline = gsap.timeline({
      scrollTrigger: {
        start: () => `+=${calcScrollStartPoint(`products`)} bottom`,
        end: `+=500`,
        scrub: 2,
        invalidateOnRefresh: true,
      },
    });

    timeline.to(logo.current, {
      y: () =>
        -(window.innerHeight / 2 - logo.current.scrollHeight / 2) + logoOffset,
      scale: 0.33,
    });

    gsap.set(logo.current, {
      scale: 0.33,
      autoAlpha: 0,
      y: 0,
    });

    gsap.to(logo.current, {
      duration: 1,
      scale: 1,
      autoAlpha: 1,
      ease: `back.out(1.7)`,
      delay: 1,
      immediateRender: false,
    });

    return () => {
      timeline.scrollTrigger.kill();
    };
  }, []);

  return (
    <header className="mobile-menu fixed top-0 left-0 w-screen bg-transparent lg:hidden flex justify-center items-center p-7 z-[100]">
      <Hamburger cross={opened} onClick={onToggle} />
      <MobileNavigation
        navigation={navigation}
        opened={opened}
        onClick={onClick}
        onTransitionEnd={onTransitionEnd}
      />
      <a
        ref={logo}
        href="/"
        className="mobile-menu__logo fixed top-1/2 left-1/2 w-30 h-30 transform -translate-y-1/2 -translate-x-1/2"
        onClick={scrollTo}
      >
        <Logo />
      </a>
    </header>
  );
}

export default MobileMenu;
