import React from 'react';
import DesignersGridItem, {
  Props as DesignersGridItemProps,
} from '@/components/modules/DesignersGridItem';

interface Props {
  items: DesignersGridItemProps[];
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function DesignersGrid({ items, onClick }: Props) {
  return (
    <div className="designers-grid [flex:0_0_80vw] grid grid-cols-2 md:grid-cols-4 lg:max-w-120">
      {items.map((item, i) => (
        <DesignersGridItem
          key={`item-${i}`}
          {...item}
          parent="designers"
          onClick={onClick}
        />
      ))}
    </div>
  );
}

export default DesignersGrid;
