import React from 'react';
import Designer, {
  Props as DesignerProps,
} from '@/components/modules/Designer';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Fade from '@/components/animations/Fade';

interface Props {
  designers: DesignerProps[];
  background: IGatsbyImageData;
}

function DesignersList({ designers, background }: Props) {
  return (
    <div className="designers-list pr-18 lg:pr-0">
      {designers.map((designer) => (
        <Fade key={designer.name}>
          <Designer key={designer.name} {...designer} background={background} />
        </Fade>
      ))}
    </div>
  );
}

export type { Props };
export default DesignersList;
