import React from 'react';
import Parallax, {
  Props as ParallaxProps,
} from '@/components/animations/Parallax';
import Float, { Props as FloatProps } from '@/components/animations/Float';
import ImageWrapper, {
  Props as ImageWrapperProps,
} from '@/components/modules/ImageWrapper';

type Props = ParallaxProps & FloatProps & ImageWrapperProps;

function ParallaxFloatElement({
  className,
  containerAnimation,
  trigger,
  horizontal,
  movement,
  direction,
  markers,
  maxRadius,
  maxSpeed,
  data,
  mobile,
  alt,
}: Props) {
  return (
    <Parallax
      className={className}
      containerAnimation={containerAnimation}
      trigger={trigger}
      horizontal={horizontal}
      movement={movement}
      direction={direction}
      markers={markers}
    >
      <Float maxRadius={maxRadius} maxSpeed={maxSpeed}>
        <ImageWrapper data={data} mobile={mobile} alt={alt} />
      </Float>
    </Parallax>
  );
}

export default ParallaxFloatElement;
