import React, { useCallback } from 'react';
import ImageWrapper from '@/components/modules/ImageWrapper';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  image?: IGatsbyImageData;
  name?: string;
  parent?: string;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function DesignersGridItem({ image, name, parent, onClick }: Props) {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      onClick(null, name);
    },
    [name]
  );

  return (
    <div className="designers-grid-item aspect-square">
      {name && (
        <a href={`?section=${parent}&subsection=${name}`} onClick={handleClick}>
          <ImageWrapper className="w-full h-full" data={image} alt={name} />
        </a>
      )}
      {!name && image && (
        <ImageWrapper className="w-full h-full" data={image} alt="" />
      )}
    </div>
  );
}

export type { Props };
export default DesignersGridItem;
