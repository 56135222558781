import { INavigationItem, Navigation } from '@/types';

interface IAnchor {
  id: string;
  element: HTMLElement;
  target: string | number;
}

export const calcScrollStartPoint = (item: string): number => {
  const target = item.toLowerCase();
  const el = document.getElementById(target);
  const anchor: IAnchor = {
    id: ``,
    element: null,
    target: null,
  };

  if (!el) return 0;
  if (el.offsetParent.className.includes(`horizontal-scrolling`)) {
    const offsetLeft =
      el.offsetLeft *
      (el.offsetParent.scrollWidth /
        (el.offsetParent.scrollWidth - el.parentElement.offsetWidth || 1));

    anchor.id = el.offsetParent.getAttribute(`data-anchor`);
    anchor.element = document.getElementById(anchor.id);
    anchor.target =
      Math.round(offsetLeft) > el.offsetParent.scrollWidth
        ? el.offsetParent.scrollWidth
        : anchor.element.offsetTop + offsetLeft;
  } else anchor.target = el.offsetTop;
  return Math.round(anchor.target);
};

export const map = (items: Omit<INavigationItem, `startPoint`>[]) =>
  items.map(
    (item): INavigationItem => ({
      label: item?.label || item.name,
      name: item.name.toLowerCase(),
      startPoint: calcScrollStartPoint(item.name),
      parent: item?.parent || null,
    })
  );

export const getSectionRange = (
  items: INavigationItem[],
  sectionStart: string,
  sectionEnd: string
) => {
  const { startPoint: start } = items.find(({ name }) => name === sectionStart);
  const { startPoint: end } = items.find(({ name }) => name === sectionEnd);
  return { start, end };
};

export const getSubSections = (navigation: Navigation) =>
  Object.keys(navigation).reduce((acc: INavigationItem[], key) => {
    acc.push(...navigation[key].filter(({ parent }) => parent));
    return acc;
  }, []);
