import { graphql } from 'gatsby';

export const fragmentImage = graphql`
  fragment Image on STRAPI__MEDIA {
    id
    name
    alternativeText
    url
    localFile {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export const fragmentImageResponsive = graphql`
  fragment ImageResponsive on STRAPI__COMPONENT_ASSETS_IMAGE {
    desktop {
      ...Image
    }
    mobile {
      ...Image
    }
  }
`;

export const fragmentSeo = graphql`
  fragment StrapiSeo on STRAPI_MAIN_SEO {
    defaultTitle: title
    titleTemplate
    defaultDescription: description
    defaultImage: image {
      ...Image
    }
  }
`;

export const fragmentMenu = graphql`
  fragment StrapiMenu on STRAPI_MENU {
    items {
      name
      sectionId
    }
  }
`;

export const fragmentHome = graphql`
  fragment StrapiHome on STRAPI_HOME {
    firstText
    secondText
    thirdText
    iosFallbackImage {
      ...Image
    }
    backgrounds {
      ...ImageResponsive
    }
    separator {
      ...ImageResponsive
    }
  }
`;

export const fragmentMarchio = graphql`
  fragment StrapiMarchio on STRAPI_MARCHIO {
    backgrounds {
      ...ImageResponsive
    }
    separator {
      ...ImageResponsive
    }
  }
`;

export const fragmentManifesto = graphql`
  fragment StrapiManifesto on STRAPI_MANIFESTO {
    backgrounds {
      ...ImageResponsive
    }
    separator {
      ...ImageResponsive
    }
    content {
      data {
        content
      }
    }
  }
`;

export const fragmentIdeazione = graphql`
  fragment StrapiIdeazione on STRAPI_IDEAZIONE {
    founders {
      alignEnd
      column1 {
        data {
          column1
        }
      }
      column2 {
        data {
          column2
        }
      }
      image {
        ...Image
      }
      name
    }
    backgrounds {
      ...ImageResponsive
    }
    separator {
      ...ImageResponsive
    }
  }
`;

export const fragmentProdotti = graphql`
  fragment StrapiProdotti on STRAPI_PRODOTTI {
    coverImage {
      ...ImageResponsive
    }
    backgrounds {
      ...ImageResponsive
    }
    products {
      id
      author
      coverImage {
        ...Image
      }
      description {
        data {
          description
        }
      }
      links {
        url
        label
      }
      productUrl
      socials {
        type
        url
      }
      productGallery {
        ...Image
      }
      productImage {
        ...Image
      }
      year
      title
    }
  }
`;

export const fragmentTeam = graphql`
  fragment StrapiTeam on STRAPI_TEAM {
    members {
      image {
        ...Image
      }
      title
      textLeft
      description
      separator
    }
    backgrounds {
      ...ImageResponsive
    }
    separator {
      ...ImageResponsive
    }
  }
`;

export const fragmentEmersivi = graphql`
  fragment StrapiEmersivi on STRAPI_EMERSIVI {
    headingLogo {
      ...Image
    }
    entriesSeparator {
      ...ImageResponsive
    }
    content {
      data {
        content
      }
    }
    backgrounds {
      ...ImageResponsive
    }
    illustration {
      ...Image
    }
    oddBackgrounds {
      ...ImageResponsive
    }
    separator {
      ...ImageResponsive
    }
    entries {
      image1 {
        ...Image
      }
      image2 {
        ...Image
      }
      text {
        data {
          text
        }
      }
    }
  }
`;

export const fragmentDesigner = graphql`
  fragment StrapiDesigner on STRAPI_DESIGNER {
    designers {
      portrait {
        ...Image
      }
      name
      label
      description {
        data {
          description
        }
      }
      images {
        ...Image
      }
    }
    grid {
      image {
        ...Image
      }
      name
    }
    background {
      ...ImageResponsive
    }
  }
`;

export const handleUploadUrl = (url: string | undefined) =>
  url ? `${process.env.GATSBY_STRAPI_API_URL}${url}` : ``;
